import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/invoice'

class InvoiceService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    index(page, setParams) {
        var queryUrl = '';
        var params = {...setParams};
        var searchInvoiceLines = false;
        if (params && page) {
            if(params['lazyEvent'] && params['lazyEvent'].includes('"filters":{"global":{"value":"#')){
                params['lazyEvent'] = params['lazyEvent'].replace('"filters":{"global":{"value":"#', '"filters":{"global":{"value":"');
                searchInvoiceLines = true;
            }
          const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
          queryUrl = `&page=` + page + `&` + queryParams;
          if(searchInvoiceLines){
            queryUrl += ('&search_invoicelines=1');
          }
        }
        return http.get(`${this.getUrl()}?from=overview` + queryUrl)
    }
    downloadPDF(id){
        return http.get(`${URL}/download/${id}`, {
            responseType: 'blob'
        })
    }
    showPDF(data){
        return http.post(`${URL}/pdf/show`, data, {
            responseType: 'blob'
        })
    }
    finalize(data, id){
        return http.post(`${this.getUrl()}/finalize/${id}`, data)
    }
    findReference(jobnumber){
        return http.get(`${URL}/findref/${jobnumber}`)
    }
    export(id){
        return http.post(`/api/export/invoice/${id}`)
    }
    checkForPOLDate(voyage, pol){
        return http.post(`${URL}/search/pol-date`, {"voyage": voyage, "pol": pol})
    }
}

export default new InvoiceService()