import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/bill-of-lading'

class BillOfLadingServic extends ApiService {
    constructor(){
        super()
    } 
    getUrl(){
        return URL
    }
    index(page, params) {
        var queryUrl = ''
        if (params && page) {
            let paramObj = params;
            let lazyEvent = paramObj['lazyEvent'];
            let pol = false;
            let pod = false;
            let chassisnr = false;
            if(lazyEvent){
                let regex = new RegExp('"filters":{"global":{"value":"(...-...)"');
                let result = regex.exec(lazyEvent);
                if(regex.test(lazyEvent)){
                    paramObj['lazyEvent'] = paramObj['lazyEvent'].replace(regex, '"filters":{"global":{"value":null');
                    let polpod = result[1].split('-');
                    pol = polpod[0];
                    pod = polpod[1];
                }
                else{
                    //eslint-disable-next-line
                    regex = new RegExp('"filters":{"global":{"value":"([A-Za-z0-9]{17})"');
                    result = regex.exec(lazyEvent);
                    if(regex.test(lazyEvent)){
                        paramObj['lazyEvent'] = paramObj['lazyEvent'].replace(regex, '"filters":{"global":{"value":null');
                        chassisnr = result[1];
                    }
                }
            }
            const queryParams = Object.keys(paramObj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(paramObj[k])).join('&');
            queryUrl = `?page=` + page + `&` + queryParams;
            if(pol && pod){
                queryUrl += `&pol=${pol}&pod=${pod}`;
            }
            if(chassisnr){
                queryUrl += `&chassisnr=${chassisnr}`;
            }
        }
        return http.get(`${URL}` + queryUrl)
    }
    downloadPDF(type, value = 1){
        return http.get(`${URL}/download/${type}?background=${value}`, {
            responseType: 'blob'
        })
    }
    showWithJobData(id){
        return http.get(`${URL}/${id}?with_job=1`)
    }
    changeBLOfJobUnit(jobUnitId, billOfLadingId){
        return http.put(`/api/job-unit/change-job-unit/bl/`, {'job_unit_id': jobUnitId, 'bill_of_lading_id': billOfLadingId})
    }
}

export default new BillOfLadingServic()