<template v-if="formActive">

    <template v-if="!user.id">
        <FormItem id="externalUser" :label="$t('overview.external_user')" v-model="user.externalUser" type="checkbox" />
        <template v-if="!user.externalUser">
            <FormItem id="name" :label="$t('overview.name')" type="livesearch" :options="azureusers" v-model="newUser" :content="''" nameField="displayName" :required="true" />
        </template>
        <template v-else>
            <FormItem id="email_e" :label="$t('form.email')" v-model="user.email" type="email" :required="true" />
        </template>
    </template>

    <template v-else>
        <FormItem id="name" :label="$t('overview.name')" v-model="user.name" :disabled="true" />
        <FormItem id="email" :label="$t('form.email')" v-model="user.email" :disabled="true" />
    </template>

    <FormItem id="usergroup" :label="$t('overview.user_group')" v-model="user.user_group_id" type="select" :options="userGroups" />
    <template v-if="userGroupsById[user.user_group_id] == 'User'">
        <FormItem id="office_id" :label="$t('crm.company')" v-model="user.office_id" type="select" :options="offices" @onchange="setBranches" :required="true" :disabled="officeDisabled" />
        <FormItem id="office_banch" v-if="branches.length > 0 && user.office_id" :label="$t('form.branch')" v-model="user.office_branch_id" type="select" :options="branches" />
        <div class="row">
            <label for="prev5" class="col-auto col-form-label col-form-label-sm pe-1">{{ $t('crm.can_approve_own') }}</label>
            <div class="col-5"><FormItem id="prev5" v-model="user.can_finalize_bls" type="checkbox" /> </div>
        </div>
        <FormItem id="radio-gruppe" label="Invoice rights" type="radiogroup" v-model="user.invoice_rights" :options="invoiceRights" />
    </template>
    <template v-else-if="userGroupsById[user.user_group_id] == 'Port operator' || userGroupsById[user.user_group_id] == 'Surveyor'">
        <FormItem id="portsx" v-model="user.ports" type="livesearch-list" :options="ports" :label="$tc('navigation.ports', 2)" :required="true" />
    </template>
    <template v-else-if="userGroupsById[user.user_group_id] == 'Customer'">
        <FormItem id="customerx" :label="$tc('navigation.customers', 1)" type="livesearch" v-model="user.customer_id" :content="user.customer_name" :options="customers" :required="true" />
        <FormItem id="office_id" :label="$t('crm.company')" v-model="user.office_id" type="select" :options="offices" :disabled="officeDisabled" />
    </template>
    <template v-else-if="userGroupsById[user.user_group_id] == 'Office Admin'">
        <FormItem id="office_id_multi" :label="$t('crm.company')" v-model="user.office_admins" type="livesearch-list" :options="offices" :required="true" :disabled="officeDisabled" />
    </template>
    <br class="my-5" />

</template>

<script>
    import azureService from '@/services/AzureService';
    import relationService from "@/services/RelationService"
    import portService from "@/services/PortService"
    import dataStore from '@/store/data';
    import store from '@/store/user';
    
    export default {
        name: 'UserForm',
        props: ['itemData'],
        data() {
            return {
                newUser: "",
                user: {
                    externalUser: false,
                    ports: [],
                    office_admin_ids: []
                },
                azureusers: [],
                offices: [],
                branches: [],
                ports: [],
                customers: [],
                userGroups: [],
                invoiceRights: [{id: 'none', name: 'None'}, {id: 'read_only', name: 'Read only'}, {id: 'full_access', name: 'Full access'}],
                userGroupsById: {},
                submitted: false,
                loading: false,
                invalid: {},
                officeDisabled: false,
                azureService: azureService,
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.user = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let valid = true;

                let required = document.querySelectorAll('#formModal input[required]');

                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select') || (field.classList.contains("simple-typeahead-input") && field.classList.contains("is-invalid"))) {
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(!valid) alert(this.$t('errors.empty_fields'));
                else if(!this.user.id && !this.user.externalUser){
                    const azureUser = this.azureusers.find(user => user.id == this.newUser);
                    this.user.email = azureUser.mail;
                    this.user.name = azureUser.displayName;
                    this.user.id = azureUser.id;
                    this.user.location = azureUser.usageLocation;
                }
                if(valid){
                    this.user.customers = [];
                    if(this.user.customer_id){
                        this.user.customers.push({id: this.user.customer_id});
                    }
                    if(this.user.user_group_id == 1){
                        this.user.office_id = 2;
                    }
                    this.user.office_admin_ids = this.user.office_admins.map(admin => admin.id);
                    this.user.office_admins = this.user.office_admins.map(admin => ({'id': admin.id, 'name': admin.name}));
                }
                return valid;
            },
            getData() {
                if (this.user.externalUser) {
                    return this.user;
                }
                if(!this.user.id) {
                    const azureUser = this.azureusers.find((element) => {
                        return element.displayName + " (" + element.userPrincipalName + ")" == this.newUser
                    });
                    if(azureUser) this.user = azureUser;
                    else {
                        this.user.office_id = null;
                        this.user.office_branch_id = null;
                    }
                }
                
                return this.user;
            },
            setData(data) {
                this.user = data
                this.user.customer_id = this.user.customers && this.user.customers[0] ? this.user.customers[0].id : null;
                this.user.customer_name = this.user.customers && this.user.customers[0] ? this.user.customers[0].relation.name : null;
                this.user.office_admin_ids = this.user.office_admins.map(admin => admin.id);
                document.getElementById('detailsSpan').innerHTML = ': ' +  this.user.name;
                /*if(this.user.office_id && this.user.user_group_id == 1){
                    this.user.user_group_id = -1;
                    const gadminIndex = this.userGroups.findIndex(group => group.id == -2);
                    this.userGroups.splice(gadminIndex, 1);
                }
                else if(this.user.user_group_id == 1){
                    this.user.user_group_id = -2;
                }
                else if(this.user.user_group_id > 1){
                    const gadminIndex = this.userGroups.findIndex(group => group.id == -2);
                    this.userGroups.splice(gadminIndex, 1);
                    const oadminIndex = this.userGroups.findIndex(group => group.id == -1);
                    this.userGroups.splice(oadminIndex, 1);
                }*/
                if(this.offices){
                    this.setBranches(false);
                }
                this.officeDisabled = store.getters.isAdmin ? false : true;
            },
            
            getAzureUsers() {
                azureService.getUsers().then(response => {
                    this.azureusers = response.data
                }).catch(error => {
                    console.log('error', error)
                })
            },
            reset() {
                this.getAzureUsers();
                this.loadData();
                this.officeDisabled = store.getters.isAdmin ? false : true;
                this.user = {externalUser: false, office_id: null, office_branch_id: null, user_group_id: null, customer_id: null, ports: [], office_admin_ids: []}
                if(this.officeDisabled){
                    this.user.office_id = store.getters.getCompanyId;
                }
                this.userGroups = dataStore.getters.getUserGroups;
                this.userGroups[0].name = 'Global Admin';
                this.userGroupsById = {null: this.$t('form.select')};
                this.userGroups.forEach(group => {
                    this.userGroupsById[group.id] = group.name;
                });
                document.getElementById('detailsSpan').innerHTML = '';
                this.newUser = null
                this.loading = false
                this.invalid = {}
            },
            loadData() {
                relationService.index(null, null, 'office-branches').then(response => {
                    this.offices = [];
                    response.data.forEach(dat => {
                        this.offices.push({
                            id: dat.office.id,
                            name: dat.name,
                            addresses: dat.addresses
                        })
                    });
                    this.setBranches(false);
                }).catch(error => {
                    console.log('error', error)
                });
                relationService.index(null, null, 'customer').then(response => {
                    this.customers = response.data;
                }).catch(error => { console.log('error', error) });
                portService.index().then(response => {
                    this.ports = response.data
                }).catch(error => {
                    console.log('error', error)
                });
            },
            setBranches(emptyId = true) {
                let thisoffice = this.offices.find(office => office.id == this.user.office_id);
                if(thisoffice){
                    let branchnames = [];
                    this.branches = [];
                    if(emptyId){
                        this.user.office_branch_id = null;
                    }
                    thisoffice.addresses.forEach(add => {
                        if(add.branch && !branchnames.includes(add.branch)){
                            this.branches.push({
                                id: add.id,
                                name: add.branch
                            });
                            branchnames.push(add.branch);
                        }
                    });
                    if(this.branches.length == 1 && emptyId){
                        this.user.office_branch_id = this.branches[0].id;
                    }
                }
            },
        }
    }
</script>