<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'created_at'"
        :sortOrder="-1"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :filterOptions="filterOptions"
        :modalSize="'modal-xl'"
        :modalWidth="'1400px'"
        :modalHeight="'90%'"
        :jobForm="true"
        @refresh-list="hideExpandersWithoutBookings"
        ref="overviewref"
    >
        <template #columns>
            <Column field="number" :header="$t('navigation.jobs')" style='width: 150px' :sortOrder="-1"></Column>
            <Column :expander="true" :sortable="false" class="job-expander" />
            <Column field="customer.relation.name" :header="$tc('navigation.customers')" style='width:12%'></Column>
            <Column field="port_of_loading.name" sortField="port~port_of_loading.name" :header="$t('sales.loading_ports')" style='width:13%'>
                <template #body="slotProps">
                    {{ getPort(slotProps.data, 'port_of_loading') }}
                </template>
            </Column>
            <Column field="etd" header="ETD" style='width:74px'>
                <template #body="slotProps">
                    <span v-if="slotProps.data.bookings.length != 1"></span>
                    <span v-else-if="slotProps.data.bookings[0].job_booking_voyage[0] && slotProps.data.bookings[0].job_booking_voyage[0].port_of_loading_date">
                        {{formatDate(slotProps.data.bookings[0].job_booking_voyage[0].port_of_loading_date, 'tinyDate')}}
                    </span>
                    <span v-else>{{formatDate(slotProps.data.etd, 'tinyDate')}}</span>
                </template>
            </Column>
            <Column field="port_of_discharge.name" sortField="port~port_of_discharge.name" :header="$t('sales.discharge_ports')" style='width:13%'>
                <template #body="slotProps">
                    {{ getPort(slotProps.data, 'port_of_discharge') }}
                </template>
            </Column>
            <Column field="eta" header="ETA" style='width:74px'>
                <template #body="slotProps">
                    <span v-if="slotProps.data.bookings.length != 1"></span>
                    <span v-else-if="slotProps.data.bookings[0].job_booking_voyage[0] && slotProps.data.bookings[0].job_booking_voyage.at(-1).port_of_discharge_date">
                        {{formatDate(slotProps.data.bookings[0].job_booking_voyage.at(-1).port_of_discharge_date, 'tinyDate')}}
                    </span>
                    <span v-else>{{formatDate(slotProps.data.eta, 'tinyDate')}}</span>
                </template>
            </Column>
            <Column :header="$tc('navigation.voyages')" :sortable="false">
                <template #body="slotProps">
                    <span v-if="slotProps.data.bookings.length === 1 && slotProps.data.bookings[0].job_booking_voyage[0] && slotProps.data.bookings[0].job_booking_voyage[0].voyage">{{slotProps.data.bookings[0].job_booking_voyage[0].voyage.name}}</span>
                </template>
            </Column>
            <Column field="office.relation.country.iso" :header="$t('navigation.offices')" style='width:80px'>
                <template #body="slotProps">
                    {{getOffice(slotProps.data)}}
                </template>
            </Column>
            <Column field="created_at" :header="$t('overview.date')" style='width:100px' :sortOrder="-1">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.created_at, 'shortDate')}}
                </template>
            </Column>
            <Column field="status" :header="$t('overview.status')" style='width:80px' :sortOrder="-1">
                <template #body="slotProps">
                    <span :class="'badge bg-' + (slotProps.data.status == 'new' ? 'warning' : (slotProps.data.status == 'active' ? 'success' : 'danger'))">{{$t('quote_status.' + slotProps.data.status)}}</span>
                </template>
            </Column>
        </template>

        <template #expandedrows="slotProps">
            <template v-if="slotProps.data.bookings.length == 1">
                <table class="p-datatable datatable-inner">
                    <thead class="solo-head">
                        <th style="width: 180px"></th>
                        <th style="width: 12%"> </th>
                        <th style="width: 13%"></th>
                        <th style="width: 74px"></th>
                        <th style="width: 13%"></th>
                        <th style="width: 74px"></th>
                        <th></th>
                        <th style="width: 80px"></th>
                        <th style="width: 100px"></th>
                        <th style="width: 80px"></th>
                        <th style="width: 80px"></th>
                    </thead>
                    <tbody>
                        <tr v-for="unit in slotProps.data.bookings[0].active_units" :key="unit.id" :id="'overviewunit-'+slotProps.data.id+'-'+unit.id" class="collapsing-body p-add-pad">
                            <td>{{ unit.commodity.name }}</td>
                            <td>{{ unit.condition_id == 2 ? $t('form.used') : $t('form.new') }}</td>
                            <td colspan="2">{{ getDescription(unit)}}</td>
                            <td colspan="2" class="vinnumber">{{ unit.chassis_number }}</td>
                            <td colspan="3">{{ getMeasurements(unit.booked_measurement) }}</td>
                            <td>
                                <span class="badge" :data-status="getUnitStatus(unit)">&hairsp;</span>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <template v-else>
                <table class="p-datatable datatable-inner" v-for="booking in slotProps.data.bookings" :key="booking.id">
                    <thead class="p-add-pad">
                        <th style="width: 180px">
                            <button @click="collapse" class="p-row-toggler p-link me-1" :data-target="'c-'+slotProps.data.id+'-'+booking.id">
                                <span class="p-row-toggler-icon pi pi-chevron-right" :data-target="'c-'+slotProps.data.id+'-'+booking.id"></span>
                            </button>
                            {{ booking.number }}
                        </th>
                        <th style="width: 12%">
                            <div class="d-flex justify-content-between align-items-center text-lowercase">
                                <span>{{ booking.active_units.length + ' ' + $tc('navigation.units', booking.active_units.length) }}</span>
                            </div>
                        </th>
                        <th style="width: 13%">{{ booking.job_booking_voyage[0] ? booking.job_booking_voyage[0].port_of_loading.name : '' }}</th>
                        <th style="width: 74px">{{ booking.job_booking_voyage[0] && booking.job_booking_voyage[0].port_of_loading_date ? formatDate(booking.job_booking_voyage[0].port_of_loading_date, 'tinyDate') : '' }}</th>
                        <th style="width: 13%">{{ booking.job_booking_voyage[0] ? booking.job_booking_voyage.at(-1).port_of_discharge.name : '' }}</th>
                        <th style="width: 74px">{{ booking.job_booking_voyage[0] && booking.job_booking_voyage.at(-1).port_of_discharge_date ? formatDate(booking.job_booking_voyage.at(-1).port_of_discharge_date, 'tinyDate') : '' }}</th>
                        <th>{{ booking.job_booking_voyage[0] ? booking.job_booking_voyage[0].voyage.name : '' }}</th>
                        <th style="width: 80px"></th>
                        <th style="width: 100px">{{formatDate(booking.created_at, 'shortDate')}}</th>
                        <th style="width: 80px">
                            <span :class="'badge bg-' + (slotProps.data.status == 'active' ? 'success' : 'danger')" style="line-height: 11.5px">{{$t('quote_status.' + slotProps.data.status)}}</span>
                        </th>
                        <th style="width: 80px"></th>
                    </thead>
                    <tbody class="d-none collapsing-body" :data-collapse="'c-'+slotProps.data.id+'-'+booking.id">
                        <tr v-for="unit in booking.active_units" :key="unit.id" :id="'overviewunit-'+slotProps.data.id+'-'+unit.id" class="p-add-pad">
                            <td class="p-set-pad">{{ unit.commodity.name }}</td>
                            <td>{{ unit.condition_id == 2 ? $t('form.used') : $t('form.new') }}</td>
                            <td colspan="2">{{ getDescription(unit)}}</td>
                            <td colspan="2" class="vinnumber">{{ unit.chassis_number }}</td>
                            <td colspan="3">{{ getMeasurements(unit.booked_measurement) }}</td>
                            <td>
                                <span class="badge" :data-status="getUnitStatus(unit)">&hairsp;</span>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </template>

        <template #form>
            <JobForm ref="ItemForm" :itemData="item" />
        </template>

        <template #modal-footer>
            <button id="nextbutton" type="button" class="btn btn-success" @click.prevent="next">&gt;&ensp;<span id="nextStep"></span></button>
            <!--<div id="confirmorrequest" style="display: none">
                <div class="d-flex gap-2">
                    <FormItem type="download" id="bookingrequestbutton" :label="$t('operations.booking_request_action')" startTag="fa fa-messages-question" :service="requestBooking" />
                    <FormItem type="download" id="bookingconfirmbutton" :label="$t('operations.booking_confirm')" startTag="fa fa-clipboard-check" :service="saveBooking" :options="{send: true}" />
                </div>
            </div>
            <div id="pendingbookingconfirmbutton" class="d-none">
                <FormItem type="download" id="pendingdownloadbutton" :label="$t('operations.booking_confirm')" startTag="fa fa-clipboard-check" :service="saveBooking" :options="{send: true}" />
            </div>-->
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import JobForm from '@/components/jobs/JobForm.vue';
import jobService from '@/services/JobService';
//import regionService from '@/services/RegionService';
//import customerService from '@/services/CustomerService';
import officeService from '@/services/FinanceSettingService';
import userService from '@/services/UserService';
import voyageService from '@/services/VoyageService';

export default {
    components: {
        Overview,
        Column,
        JobForm
    },
    data() {
        return {
            item: {},
            itemService: jobService,
            itemFormRef: null,
            filterOptions: [
                {
                    field: 'voyages.id',
                    service: voyageService,
                    name: this.$t('navigation.voyages'),
                    extraOptions: {"has": "jobs", "minlength": 3}
                },
                /*{
                    field: 'allRegionsFilter',
                    service: regionService,
                    name: this.$t('navigation.regions')
                },
                {
                    field: 'customer_id',
                    service: customerService,
                    name: this.$t('navigation.customers')
                },*/
                {
                    field: 'users.user_id',
                    service: userService,
                    name: this.$t('navigation.users')
                },
                {
                    field: 'office_id',
                    service: officeService,
                    name: this.$t('navigation.offices')
                },
                {
                    field: 'status',
                    checkboxes: [
                        {id: "'active'", name: this.$t('quote_status.active')},
                        {id: "'new'", name: this.$t('quote_status.new')},
                        {id: "'pending'", name: this.$t('quote_status.pending')}],
                    name: this.$t('overview.status')
                },
                {
                    field: 'jobUnits.chassis_number',
                    capitals: true,
                    name: this.$t('sales.vin') + " (Last 6 chars)"
                }
            ]
        }
    },
    methods: {
        next() {
            let tabs = document.querySelectorAll("#formModal ul.nav-tabs .nav-link");
            let next = false;
            for(let tab of tabs) {
                if(next) {
                    tab.click();
                    break;
                }
                if(tab.className == "nav-link active") next = true;
                
            }
        },
        saveBooking(){
            return this.$refs.ItemForm.createBooking();
        },
        requestBooking(){
            return this.$refs.ItemForm.createBooking(true);
        },
        getPort(data, portType){
            try{
                if(data.bookings.length == 1 && data.bookings[0].job_booking_voyage.at(-1)[portType].name){
                    return data.bookings[0].job_booking_voyage.at(-1)[portType].name;
                }
                return data[portType].name;
            }
            catch(e){
                return data[portType].name;
            }
        },
        collapse(e){
            if(e.target.tagName === "BUTTON"){
                let child = e.target.children[0];
                child.classList.toggle("pi-chevron-right");
                child.classList.toggle("pi-chevron-down");
            }
            if(e.target.tagName === "SPAN"){
                let child = e.target;
                child.classList.toggle("pi-chevron-right");
                child.classList.toggle("pi-chevron-down");
            }
            let tbody = document.querySelector('tbody[data-collapse="'+e.target.getAttribute("data-target") + '"]');
            tbody.classList.toggle("d-none");
        },
        getDescription(unit){
            if(unit.model && unit.make){
                return unit.make.name + ' ' + unit.model.name;
            }
            return '-';
        },
        getMeasurements(unit){
            return unit ? (unit.length + ' x ' + unit.width + ' x ' + unit.height + ' cm; ' + unit.weight + ' kg') : '';
        },
        getUnitStatus(unit){
            let statusName = 'Waiting';
            let statusId = unit.job_booking_voyage_job_units[0] ? unit.job_booking_voyage_job_units.at(-1).status_id : null;
            //if(unit.delivered_date) statusName = 'Delivered';
            if(statusId == 2) statusName = 'Firm';
            else if(statusId == 3) statusName = 'Permit';
            else if(statusId == 4) statusName = 'Loaded';
            return statusName;
        },
        getOffice(data){
            try{
                return data.office.prefix + data.office.relation.country.iso;
            }
            catch(e){
                return 'NMTNL';
            }
        },
        hideExpandersWithoutBookings(){
            this.$nextTick( () => {
                let els = document.querySelectorAll('.p-row-toggler');
                els.forEach(el => {
                    try{
                        let bookingsInner = el.parentElement.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.innerHTML;
                        if(bookingsInner == '<!---->0') el.style.display = 'none';
                    }
                    catch(e){
                        console.log(e);
                    }
                });
            });
        },
        edit(data, bIndex){
            window.tabToOpen = bIndex;
            this.$refs.overviewref.editItem(data);
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>

<style scoped>
.fa-circle{
    font-size: 12px
}
.fa[data-status="new"]{
    color: orange
}
.fa[data-status="active"]{
    color: #0c0
}
.fa[data-status="cancelled"]{
    color: red
}

.badge {
    width: 65px; 
}
.badge-small {
    width: 35px;
    background-color: #1565C0 !important;
}
.badge[data-status="Waiting"] {
    background-color: var(--bs-secondary) !important;
}
.badge[data-status="Delivered"] {
    background-color: black !important;
}
.badge[data-status="Firm"] {
    background-color: var(--bs-info) !important;
}
.badge[data-status="Permit"] {
    background-color: orange !important;
}
.badge[data-status="Loaded"] {
    background-color: var(--bs-success) !important;
}
.badge[data-status]::before {
    content: attr(data-status);
}

.badge-small.badge-fwd {
    color: #1565C0 !important;
    background-color: #fff !important;
    border: 1px solid #1565C0;
}
</style>