<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'name'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields=selectFields
    >
        <template #columns>
            <Column field="photo" :header="''" :sortable="false" style="width:60px">
                <template #body="slotProps">
                    <template v-if="slotProps.data.profile_image">
                        <img
                        :src="slotProps.data.profile_image"
                        alt=""
                        width="40"
                        height="40"
                        class="rounded-circle me-2"
                        />
                    </template>
                    <div v-else class="no-profile-img"></div>
                </template>
            </Column>
            <Column field="name" :header="$t('overview.name')"></Column>
            <Column field="email" :header="$t('form.email')" style="min-width:16rem"></Column>
            <Column field="location" :header="$t('overview.location')" style="width:7rem"></Column>
            
            <Column field="user_group.name" :header="$t('overview.user_group')" style="width:10rem">
                <template #body="slotProps">
                    {{setUserRole(slotProps.data)}}
                </template>
            </Column>
            <Column field="name" :header="$t('navigation.relations')" style="min-width:16rem" :sortable="false">
                <template #body="slotProps">
                    <template v-if="slotProps.data.user_group_id">
                        <span v-if="slotProps.data.user_group.name == 'User' && slotProps.data.office">{{slotProps.data.office.relation.name}}</span>
                        <span v-if="slotProps.data.user_group.name == 'Customer' && slotProps.data.customers">{{slotProps.data.customers[0].relation.name}}</span>
                        <span v-if="(slotProps.data.user_group.name == 'Port operator' || slotProps.data.user_group.name == 'Surveyor') && slotProps.data.ports">
                            {{slotProps.data.ports.map(port => port.name).join(', ')}}
                        </span>
                    </template>
                    <span v-else-if="slotProps.data.office">{{slotProps.data.office.relation.name}}</span>
                </template>
            </Column>
            <Column field="last_login" :header="$t('overview.last_login')" style="width:12rem">
                <template #body="slotProps">
                    <template v-if="slotProps.data.last_login">
                        {{formatDate(slotProps.data.last_login, 'fromNow')}}
                    </template>
                </template>
            </Column>
            <Column field="external" :header="$t('overview.external_user')" style="width:9rem">
                <template #body="slotProps">
                    <i v-if="slotProps.data.external" class="fas fa-check text-success" />
                </template>
            </Column>
        </template>

        <template #form>
            <UserForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import UserForm from '@/components/user/UserForm.vue';
import userService from '@/services/UserService';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        UserForm
    },
    data() {
        return {
            item: {},
            itemService: userService,
            itemFormRef: null,
            selectFields: [
                "id",
                "name",
                "email",
                "location",
                "last_login",
                "profile_image",
                "external",
                "user_group_id",
                "office_id",
                {
                    "office": [
                        "id",
                        "relation_id",
                        {
                            "relation": [
                                "name"
                            ]
                        }
                    ]
                },
                {
                    "customers": [
                        "id",
                        "relation_id",
                        {
                            "relation": [
                                "name"
                            ]
                        }
                    ]
                },
                {
                    "userGroup": [
                        "id",
                        "name"
                    ]
                },
                {
                    "ports": [
                        "id",
                        "name"
                    ]
                }
            ]
        }
    },
    methods: {
        setUserRole(data) {
            if(!data.user_group){
                return '-';
            }
            if(data.user_group.id == 1){
                return "Global Admin";
            }
            return data.user_group.name;
        },
    },
    mounted() {
        if(!store.getters.isAdmin){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>
<style scoped>
.no-profile-img{
    width: 40px;
    height: 40px;
    background-color: #eee;
    border-radius: 1000px;
}
</style>