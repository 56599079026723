<template>
<div id="invoices-popup" v-if="formActive" :class="{'read-only': !canEditInvoices}">
    <div id="invoiceoptions">
        <p v-show="isApproved">{{ $t('accounting.invoice_approved_blurb') }}</p>
        <div class="row">
            <div class="col-3" v-if="preloading">
                <FormItem id="comp_id" v-model="invoice.office_id" :disabled="!canEditCompany || isApproved" type="livesearch" :options="companies" :labelAbove="$t('crm.company')" />
            </div>
            <div class="col-3" v-else>
                <FormItem id="comp_id" v-model="invoice.office_id" :disabled="!canEditCompany || isApproved" type="livesearch" :options="companies" 
                         :content="safeFind(companies, 'id', invoice.office_id, 'name')" :required="true" @onchange="getVatPercentages" :labelAbove="$t('crm.company')" />
            </div>
            <div class="col-7"></div>
            <div class="col-2">
                <FormItem id="curr" v-model="invoice.currency_id" type="select" sizeItem="4" :options="currencies" nameField="code" :content="invoice.currency_id ? targetCurrency.code : ''"
                        @onchange="currencyOnChange" :required="true" :labelAbove="$t('accounting.invoice_currency')" :disabled="isApproved" :selectDefault="false" />
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-8">
                <div class="row">
                    <div class="col-auto" style="width: 37.5%">
                        <FormItem id="customer_id" v-model="invoice.customer_id" type="livesearch" :service="customerService" nameField="name" :disabled="isApproved" :content="customerContent"
                                 :required="true" @onchange="getCustomerInfo" :labelAbove="$t('navigation.customers')" :extraOptions="{ignore: invoice.office_id, minlength: 3}" />
                    </div>
                    <div class="col-auto" style="width: 25%">
                        <FormItem id="ca_id" type="select" :selectDefault="0" :options="addressOptions" v-model="invoice.customer_address_id" :disabled="isApproved || customerIsNaturalPerson" 
                                 :labelAbove="$t('form.branch')" @onchange="(a, b, c) => invoice.customer_address = c.content" />
                    </div>
                    <div class="col-auto" style="width: 37.5%">
                        <FormItem id="dep" v-model="invoice.department" labelAbove="Att." :disabled="isApproved || customerIsNaturalPerson" />
                    </div>
                </div>
                <div class="row">
                    <FormItem id="customer_address" v-model="invoice.customer_address" type="textarea" :disabled="true" :labelAbove="$t('navigation.addresses')" class="w-50" options="5" :required="true" />
                    <FormItem id="client_notice" v-model="invoice.client_notice" type="textarea" :disabled="true" :labelAbove="$t('crm.client_notice')" class="w-50" options="5" />
                </div>
            </div>
            <div class="col-2">
                <FormItem id="cref" v-model="invoice.customer_reference" :labelAbove="$t('accounting.customer_reference')" :disabled="isApproved" />  
                <FormItem id="customer_vat_id" v-model="invoice.customer_vat_id" :disabled="true" :labelAbove="$t('accounting.vat_id')" :required="isVATRequired" />
            </div>
            <div class="col-2" id="currencylist">
                <template v-for="currency, cindex in totals.sub" :key="cindex">
                    <template v-if="targetCurrency.code != currency.code && invoice.exchange_rate && invoice.exchange_rate[currency.id]">
                        <label class="col-form-label col-form-label-sm required-label">{{ $t('accounting.exchange_rate') }}</label>
                        <FormItem :id="'exch_'+currency.id" type="number-with-tags" v-model="invoice.exchange_rate[currency.id].display_value" @onchange="exRateOnChange(invoice.exchange_rate[currency.id], currency.id)"
                                  :endTag="(currency.code == companyCurrency.code ? targetCurrency.code : currency.code) + '/' + companyCurrency.code" :required="true" :disabled="isApproved" />
                    </template>
                </template>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-2">
                <FormItem :labelAbove="$t('form.x_number', [$t('navigation.invoices')])" v-model="invoice.number" :disabled="true" :required="true" />
            </div>
            <div class="col-2">
                <FormItem id="date" type="datepicker" v-model="invoice.date" :required="true" :disabled="true" :labelAbove="$t('accounting.invoice_date')" />
            </div>
            <div class="col-2">
                <FormItem id="paymentterms" type="select" v-model="invoice.payment_terms_id" :options="paymentTerms" :required="true" :disabled="isApproved" :labelAbove="$tc('navigation.payment_terms', 2)" />
            </div>
            <div class="col-2">
                <FormItem type="select" v-model="invoice.vat_type_id" @onchange="invoiceOnChange()" :required="true" :disabled="isApproved" :options="defaultVatAmounts" :labelAbove="$t('accounting.vat')" />
            </div>
            <div class="col-2">
                <FormItem v-if="invoice.fac == -1" id="fac_manual" v-model="invoice.fac_manual" @onchange="invoiceOnChange()" type="number-with-tags" endTag="%" :required="true" :disabled="isApproved" :labelAbove="$t('accounting.fac')" />
                <FormItem v-else id="fac" type="select" v-model="invoice.fac" @onchange="invoiceOnChange()" :selectDefault="false" :required="true" :disabled="isApproved" :options="defaultFacAmounts" :labelAbove="$t('accounting.fac')"  />
            </div>
            <div class="col-2" >
                <FormItem v-if="invoice.fac" id="fac_type" type="select" v-model="invoice.fac_type" @onchange="invoiceOnChange()" :required="true" :disabled="isApproved" :options="defaultFacTypeAmounts" :labelAbove="$t('accounting.fac_type')" />
            </div>
        </div>

        <div class="row">
            <div class="col-form-label col-form-label-sm form-label-above w-25 mt-2">{{$t('navigation.mainport_projects')}}</div>
            <div class="col-form-label col-form-label-sm form-label-above w-25 mt-2">{{$t('overview.type')}} *</div>
        </div>
        <div class="row" style="min-height: 29px">
            <div class="col-3 d-flex">
                <FormItem id="is_project" v-model="invoice.is_project" :disabled="isApproved" type="checkbox" />
                <div class="ps-3" style="width: 95%">
                    <FormItem v-if="invoice.is_project" id="mainport_project_id" v-model="invoice.mainport_project_id" type="livesearch" :service="projectService" nameField="name" sortField="number"
                        :content="invoice.mainport_project ? invoice.mainport_project.name : ''" :required="invoice.is_project" @onchange="setProjectData" :disabled="isApproved" />
                </div>
            </div>
            <div class="col-3">
                <FormItem id="invoicetype" type="select" v-model="invoice.invoice_type_id" :disabled="isApproved" :options="[{id: 1, name: 'Agent invoice'}, {id: 2, name: 'Customer invoice'}]" placeholder="Default" @onchange="onInvoiceTypeChange" />
            </div>
        </div>

        <div class="row mt-2" v-if="invoice.is_project">
            <div class="col-3">
                <FormItem id="voyage" v-model="invoice.voyage" :disabled="isApproved" :labelAbove="$t('navigation.voyages')" @onchange="checkForPOLDate(); setCasing('voyage')" :required="true" />
            </div>
            <div class="col-3">
                <FormItem id="loading_port" v-model="invoice.port_of_loading" :disabled="isApproved" @onchange="checkForPOLDate(); setCasing('port_of_loading')" :labelAbove="$t('sales.loading_ports')" :required="true" />
            </div>
            <div class="col-2">
                <FormItem id="pol_date" v-model="invoice.port_of_loading_date" type="datepicker" :disabled="isApproved || invoice.port_of_loading_date === undefined" 
                                       :labelAbove="$t('sales.loading_ports') + ' - ' + $t('overview.date')" :required="true" />
            </div>
            <div class="col-3">
                <FormItem id="discharge_port" v-model="invoice.port_of_discharge" @onchange="setCasing('port_of_discharge')" :disabled="isApproved" :labelAbove="$t('sales.discharge_ports')" />
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-3">
                <FormItem id="jobno" v-model="invoice.job_number" :disabled="isApproved" :labelAbove="$t('form.x_number', [$t('navigation.jobs')])" @onchange="findCustomerRef" />
            </div>
            <div class="col-3">
                <FormItem id="salesmanager" v-model="invoice.sales_manager" :disabled="isApproved" :labelAbove="$t('sales.sales_contact')" />
            </div>
            <div class="col-2">
                <label class="col-form-label col-form-label-sm form-label-above">{{ $tc('sales.option', 2) }}</label>
                <FormItem id="show_vins_type" v-model="invoice.show_vins_type" :disabled="isApproved" type="select" :options="vinModeTypes" :selectDefault="false" @onchange="setDescriptionVins" />
            </div>
            <div class="col-2">
                <label class="col-form-label col-form-label-sm">&nbsp;</label>
                <FormItem id="show_collect" v-model="invoice.show_collect" :disabled="isApproved" type="checkbox" label="Show collect column" @onchange="invoiceOnChange" />
            </div>
        </div>
    </div>
    
    <br><hr><br>

    <div class="row mb-0 transport-unit-table">
        <div class="col-tiny"></div>
        <div class="col col-3p5 col-form-label-sm required-label"> {{$t('form.description')}} </div>
        <div class="col-sm-xs col-form-label-sm required-label"> {{$t('form.qt')}} </div>
        <div class="col-2 col-form-label-sm required-label"> {{$t('sales.sales_rates')}} </div>
        <div class="col-sm-1 col-form-label-sm required-label"> {{$t('form.per')}} </div>
        <div class="col-sm-1 col-form-label-sm required-label" :class="{'invisible': invoice.vat_type_id != 3}"> {{$t('accounting.vat')}} </div>
        <div class="col-sm-2 col-form-label-sm" style="position: relative">
            <span v-if="invoice.show_collect">{{$t('operations.collect')}}</span>
            <span v-if="invoice.fac" style='position:absolute; right: 0px'>FAC</span>
        </div>
        <div class="col-1 col-form-label-sm text-end"> {{$t('overview.total')}} </div>
        <div class="col-icon"></div>
    </div>
    <template v-for="item, itemIndex in invoice.invoice_lines" :key="itemIndex">
        <div class="row drag-and-drop droppable dragging-row" v-html="dragData.before && dragData.dragger && dragData.dropIndex == itemIndex ? dragData.dragger : ''"
            @dragenter="onDragOver($event, true, itemIndex)" @dragleave="onDragOver($event, false)" @drop.prevent="" @dragover="setBefore"></div>
        <div @dragenter="onDragOver($event, true, itemIndex)" @dragleave="onDragOver($event, false)" @drop.prevent="" @dragover="setBefore" :class="{'opaque': dragData.isDragging && dragData.dropIndex != null && dragData.dragIndex == itemIndex}">
        <div class="row transport-unit-table drag-and-drop position-relative" :class="{'droppable': dragData.isDragging}">
            <i class="col-tiny fa fa-sort" draggable="true" @dragstart="onDragStart($event, itemIndex)" @dragend="onDragEnd"></i>
            <div class="col col-3p5" :id="'invoice-line-' + itemIndex">
                <textarea class="form-control form-control-sm description" :class="{'no-resize': (item.description.indexOf('\n') === -1)}" v-model="item.description" :required="true" :disabled="isApproved"
                        :rows="(item.description.indexOf('\n') === -1) ? 1 : 3"></textarea>
                <FormItem v-if='invoice.show_vins_type && item.description_vins != null' type='textarea' v-model="item.description_vins" class="mt-1" :disabled="isApproved" />
            </div>
            <template v-if="item.amount">
            <div class="col-sm-xs">
                <FormItem type="number" v-model="item.quantity" :required="true" :disabled="isApproved" @onchange="invoiceOnChange" />
            </div>
            <div class="col-2">
                <FormItem v-model="invoice.invoice_lines[itemIndex]" type="currency-and-amount" :options="currencies" extraOption="5" nameField="code" :calcUnitFields="SCalcUnitFields" :required="true" :disabled="isApproved" @onchange="invoiceOnChange" />
            </div>
            <div class="col-sm-1">
                <FormItem v-model="item.sales_rate_calculation_unit_id" type="select" :options="calcUnitsNoPerc" :required="true" :selectDefault="false" :disabled="isApproved" @onchange="invoiceOnChange" />
            </div>
            <div class="col-sm-1">
                <FormItem type="select" v-if="invoice.vat_type_id == 3" v-model="item.vat" :required="true" :disabled="isApproved" :options="vatAmounts" @onchange="invoiceOnChange" />
            </div>
            <div class="col-2 col-combined">
                <FormItem v-if="!item.quantity || item.quantity == '0' || !invoice.show_collect" :id="'amcheck-'+itemIndex" v-model="item.amount" :disabled="isApproved" 
                          type="checkbox-with-values" :options="[0, 1]" label="Description only" @onchange="invoiceOnChange"  />
                <FormItem v-else-if="invoice.show_collect" v-model="invoice.invoice_lines[itemIndex]" type="currency-and-amount" :options="currencies" 
                          nameField="code" :calcUnitFields="CCalcUnitFields" @onchange="invoiceOnChange" :disabled="isApproved" />
            
                <FormItem v-if="invoice.fac" type="checkbox" v-model="item.calc_fac" @onchange="invoiceOnChange" :disabled="isApproved" style="position: absolute; right: 0px; top: 3px"/>
            </div>
            <div class="col-1 total-field text-end ">
                {{ formatCurrency((item.sales_rate * (item.sales_rate_calculation_unit_id == 6 ? 1 : item.quantity) * (item.sales_rate_calculation_unit_id == 1 ? 1 : item.amount)) - (invoice.show_collect && item.collect ? item.collect : 0) ) }} 
                {{ findCurrency(item.sales_rate_currency_id) }}
            </div>
            </template>
            <div v-else style="position: absolute; right: 15.8%; width: 13%">
                <FormItem :id="'amcheck-'+itemIndex" v-model="item.amount" :disabled="isApproved" type="checkbox-with-values" :options="[0, 1]" label="Description only" @onchange="invoiceOnChange" />
            </div>
            <div class="col-icon" style="position: absolute; right: 0">
                <button type="button" v-show="!isApproved" :id="'dropdownMenuButton_'+itemIndex" class="btn btn-secondary btn-sm" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-light fa-ellipsis"></i></button>
                <ul class="dropdown-menu dropdown-menu-end" :aria-labelledby="'dropdownMenuButton_'+itemIndex" >
                    <li>
                        <a class="dropdown-item" @click="copyInvoiceLine(itemIndex)"> <i class="fal fa-copy"></i> {{ $t('form.copy') }} </a>
                    </li>
                    <li>
                        <a class="dropdown-item" @click="addSurcharge(itemIndex)"> <i class="fal fa-plus"></i> {{ $t('form.add', [$t('navigation.surcharges')]) }} </a>
                    </li>
                    <li v-show="item.description_vins == null">
                        <a class="dropdown-item" @click="addDescVINs(item)"> <i class="fal fa-plus"></i> {{ $t('form.add', ['VINs']) }} </a>
                    </li>
                    <li v-show="item.description_vins != null">
                        <a class="dropdown-item" @click="item.description_vins = null"> <i class="fal fa-remove"></i> {{ $t('form.remove', ['VINs']) }} </a>
                    </li>
                    <li>
                        <a class="dropdown-item" @click="removeInvoiceLine(itemIndex)"> <i class="fal fa-trash-alt"></i> {{ $t('form.delete') }} </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row transport-unit-table drag-and-drop surcharge-row" v-for="surcharge, sIndex in item.surcharges" :key="sIndex" :class="{'droppable': dragData.isDragging}">
            <i class="col-tiny"></i>
            <div class="col col-3p5" :id="'invoice-line-' + itemIndex + '-s-' + sIndex">
                <textarea class="form-control form-control-sm description" :class="{'no-resize': (surcharge.description.indexOf('\n') === -1)}" v-model="surcharge.description" :required="true" :disabled="isApproved"
                        :rows="(surcharge.description.indexOf('\n') === -1) ? 1 : 3"></textarea>
            </div>
            <div class="col-sm-xs"></div>
            <div class="col-2">
                <FormItem v-if="surcharge.sales_rate_calculation_unit_id == 5" type="number" v-model="surcharge.sales_rate" :required="true" :disabled="isApproved" @onchange="invoiceOnChange" />
                <FormItem v-else v-model="item.surcharges[sIndex]" type="currency-and-amount" :options="currencies" nameField="code" :calcUnitFields="SCalcUnitFields" :required="true" :disabled="isApproved" @onchange="invoiceOnChange" />
            </div>
            <div class="col-sm-1">
                <FormItem v-model="surcharge.sales_rate_calculation_unit_id" type="select" :options="calcUnits" :required="true" :selectDefault="false" :disabled="isApproved" @onchange="invoiceOnChange" />
            </div>
            <div class="col-sm-1"></div>
            <div class="col-2 col-combined">
                <FormItem v-if="invoice.fac" type="checkbox" v-model="surcharge.calc_fac" @onchange="invoiceOnChange" :disabled="isApproved" style="position: absolute; right: 0px; top: 3px"/>
            </div>
            <div class="col-1 total-field text-end ">
                {{ formatCurrency((surcharge.sales_rate_calculation_unit_id == 5 ? (item.sales_rate * item.quantity * surcharge.sales_rate * 0.01) : surcharge.sales_rate) ) }} 
                {{ findCurrency(surcharge.sales_rate_calculation_unit_id == 5 ? item.sales_rate_currency_id : surcharge.sales_rate_currency_id) }}
            </div>
            <div class="col-icon">
                <button class="btn" style="padding: 3px 0px 1px 7px;" type="button" v-show="!isApproved" @click="removeInvoiceLine(itemIndex, sIndex)">
                    <i class="fa fa-close text-danger"></i>
                </button>
            </div>
        </div>
        </div>
        <div class="row drag-and-drop droppable dragging-row" v-html="!dragData.before && dragData.dragger && dragData.dropIndex == itemIndex ? dragData.dragger : ''"
            @dragenter="onDragOver($event, true, itemIndex)" @dragleave="onDragOver($event, false)" @drop.prevent="" @dragover="setBefore"></div>
    </template>

    <div class="row totals-row">
        <div class="col-6 pt-2" style="width: 55.6%;">
            <button type="button" class="btn btn-primary" @click="addInvoiceLine" :disabled="isApproved">
                <i class="fa fa-plus"></i>&ensp;{{ $t('form.add', [$t('accounting.invoice_lines')]) }}
            </button>
            <br />
            <button type="button" class="btn btn-primary my-2" @click="setDefaultOpen(true)" :disabled="isApproved">
                <i class="fa fa-plus"></i>&ensp;{{ $t('form.add', [$t('accounting.default_costs')]) }}
            </button>
            <br />
            <button type="button" class="btn btn-primary mb-2" @click="toggleDescOnly" :disabled="isApproved">
                <i class="fa fa-arrows-rotate"></i>&ensp;{{ $t('accounting.toggle_desc_only') }}
            </button>
        </div>
        <template v-if="targetCurrency.code != null">
        <div class="col-4 p-0" style="width: 29.5%">
            <div v-for="currency, cindex in totals.fac" :key="cindex" class="mt-1">
                <span v-show="targetCurrency.code == currency.code">{{ 'FAC in ' + targetCurrency.code }}</span>
            </div>
            <div v-for="currency, cindex in totals.fac" :key="cindex" class="mt-1">
                <span v-show="targetCurrency.code != currency.code">{{ 'FAC in ' + currency.code }}</span>
            </div>
            <div v-for="currency, cindex in totals.sub" :key="cindex" class="mt-1">
                <span v-show="targetCurrency.code == currency.code">{{ $t('overview.subtotal') + ' in ' + targetCurrency.code }}</span>
            </div>
            <div v-for="currency, cindex in totals.sub" :key="cindex" class="mt-1">
                <span v-show="targetCurrency.code != currency.code && !currency.hide">{{ $t('accounting.exchange_rate') }}
                    {{ (currency.code == companyCurrency.code ? (targetCurrency.code + '/' + currency.code) : (currency.code + '/' + targetCurrency.code)) + ': ' + currency.roeraw + ' (' + currency.raw  + ')'}}
                </span>
            </div>
            <div v-show="invoice.vat_type_id == 3" class="mt-1">{{ $t('accounting.total_no_vat') }}</div>
            <div v-show="invoice.vat_type_id == 2" class="mt-1">{{ $t('accounting.vat_shifted') }}</div>
            <div v-show="invoice.vat_type_id == 1" class="mt-1">{{ $t('accounting.vat_zero') }}</div>
            <template v-for="currency, vatPerc in totals.vat" :key="vatPerc">
                <div v-if="vatPerc != 0 && invoice.vat_type_id == 3" class="mt-1">{{vatPerc + '% ' + $t('accounting.vat') + ' (' + currency.raw + ')'}}</div>
            </template>
            <div class="mt-1"> <span class="fw-bold">{{ $t('accounting.grand_total_due') }}</span> </div>
        </div>
        <div class="col-sm-2 text-end pe-2 all-totals-column" style="width: 11%; font-size: .875rem">
            <div v-for="currency, cindex in totals.fac" :key="cindex" class="mt-1">
                <span v-show="targetCurrency.code == currency.code">{{ currency.total }}</span>
            </div>
            <div v-for="currency, cindex in totals.fac" :key="cindex" class="mt-1">
                <span v-show="targetCurrency.code != currency.code">{{ currency.total }}</span>
            </div>
            <div v-for="currency, cindex in totals.sub" :key="cindex" class="mt-1">
                <span v-show="targetCurrency.code == currency.code">{{ currency.total }}</span>
            </div>
            <div v-for="currency, cindex in totals.sub" :key="cindex" class="mt-1">
                <span v-show="targetCurrency.code != currency.code && !currency.hide">{{ currency.total }}</span>
            </div>
            <div v-show="invoice.vat_type_id == 3" class="mt-1">{{ totals.grandsub }}</div>
            <div v-show="invoice.vat_type_id == 2 || invoice.vat_type_id == 1" class="mt-1"><br /></div>
            <div v-show="Object.keys(totals.vat).lenth > 1" class="mt-1"></div>
            <template v-for="currency, vatPerc in totals.vat" :key="vatPerc">
                <div v-if="vatPerc != 0 && invoice.vat_type_id == 3" class="mt-1">{{ currency.total }}</div>
            </template>
            <div class="mt-1"> <span class="fw-bold">{{ this.invoice.vat_type_id == 3 ? totals.grand : totals.grandsub }}</span> </div>
        </div>
        </template>
    </div>

    <template v-if="emailActive">

        <div class="modal d-block" tabindex="-1" style="background-color: rgba(1,1,1,0.4)">
            <div class="modal-dialog modal-dialog-scrollable modal-lg" style="min-width: 1200px !important">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exLabel">{{$t('accounting.email_invoice') + ' ' + invoice.number}}</h5>
                        <button type="button" id="inner-close-btn" class="btn-close" @click="emailActive = false"></button>
                    </div>
                    <div class="modal-body p-3">
                        <FormItem v-model="emailFromDisplay" :label="$t('overview.from')" sizeLabel="2" sizeItem="10" :disabled="true" />
                        <FormItem v-model="emailData.reply_to" :label="$t('form.email_reply_to')" sizeLabel="2" sizeItem="5" />
                        <div class="d-flex mb-2">
                            <label class="col-form-label col-form-label-sm" style="width: 17%"> {{$t('overview.to')}} </label>
                            <div style="width: 40.5%">
                                <FormItem v-model="emailData.to" type="textarea" />
                            </div>
                            <div class="w-25 ps-2">
                                <FormItem v-model="emailData.is_cc" label="CC" type="checkbox" />
                                <FormItem v-model="emailData.is_bcc" label="BCC" type="checkbox" />
                            </div>
                        </div>
                        <FormItem v-if="emailData.is_cc" v-model="emailData.cc" label="CC" sizeLabel="2" sizeItem="5" type="textarea" />
                        <FormItem v-if="emailData.is_bcc" v-model="emailData.bcc" label="BCC" sizeLabel="2" sizeItem="5" type="textarea" />
                        <FormItem v-model="emailData.subject" :label="$t('form.email_subject')" sizeLabel="2" sizeItem="5" />
                        <div class="d-flex mb-2">
                            <label class="col-form-label col-form-label-sm" style="width: 17%"> {{$t('form.email_att')}} </label>
                            <div style="width: 40.5%">
                                <input type="file" multiple="" class="d-none" @change="uploadAttachment($event, true)" />
                                <div class="file-drop" @dragover="dropzoneDragOver" @dragleave="dropzoneDragLeave" @drop="uploadAttachment($event)" @click="$event.target.previousElementSibling.click()">
                                    {{$t('form.drop_files')}}
                                </div>
                            </div>
                            <div class="w-25 ps-2">
                                <div v-show="invoice.id">
                                    <i class="fa fa-file-pdf text-muted"></i>&nbsp;
                                    <span class="d-inline-block w-75 fake-link" @click="pdfDownload">invoice_{{invoice.number}}.pdf</span>
                                </div>
                                <div v-for="attachment, index in emailData.attachments" :key="index">
                                    <i class="fa fa-file"></i>&nbsp;
                                    <span class="d-inline-block w-75">{{attachment.name}}</span>
                                    <button type="button" @click="emailData.attachments.splice(index, 1)" class="btn p-0"><i class="fa fa-trash-alt text-danger"></i></button>
                                </div>
                            </div>
                        </div>
                        <FormItem v-model="emailData.body" :label="$t('form.email_body')" sizeLabel="2" sizeItem="5" options="10" type="textarea" />
                    </div>
                    <div class="modal-footer">
                        <FormItem type="download" :label="$t('form.send')" startTag="fa fa-send" :service="finalizeAndSendEmail" :options="{send: true}" />
                    </div>
                </div>
            </div>
        </div>

    </template>
</div>
<InvoiceDefaultCostPopup ref="defcostPopup" :currencies="currencies" :isOpen="defaultIsOpen" />

</template>

<script>
    import relationService from "@/services/RelationService";
    import customerService from "@/services/InvoiceCustomerService";
    import projectService from "@/services/MainportProjectService";
    import currencyService from "@/services/CurrencyService";
    import paymentTermService from '@/services/PaymentTermService';
    import invoiceService from '@/services/InvoiceService';
    import calcUnitService from '@/services/CalculationunitService';
    import dataStore from '@/store/data';
    import moment from 'moment';
    import store from '@/store/user';
    import InvoiceDefaultCostPopup from '@/components/invoice/InvoiceDefaultCostPopup.vue';
    export default {
        name: 'NewInvoiceForm',
        props: ['itemData', 'jobItem'],
        components: {
            InvoiceDefaultCostPopup
        },
        data() {
            return {
                invoice: { invoice_lines: [], sales_managers: [] },
                emailData: {},
                isVATRequired: true,
                emailFromDisplay: 'noreply@mg.nmtshipping.com <Accounting Dept. - NMT International Shipping B.V.>',
                commodities: [],
                currencies:[],
                makes: [],
                models: [],
                hscodes: [],
                colours: [],
                customers: [],
                companies: [],
                countries: [],
                legacyExchangeRates: {},
                dragData: {dragIndex: null, dropIndex: null, isDragging: false},
                dragImage: null,
                isReadyInvoice: false,
                isFromShipload: false,
                isApproved: false,
                emailActive: false,
                invoiceParent: null,
                voyageNumber: '',
                mouseY: 0,
                defaultIsOpen: false,
                gettingCustomerInfo: false,
                targetCurrency: {code: null},
                companyCurrency: {code: null},
                totals: {'sub': {}, 'vat': {}, 'grand': '-', 'grandsub': '-'},
                calcUnits: [],
                calcUnitsNoPerc: [],
                paymentTerms: [{id: 1, name: 'Unknown'}],
                defaultVatAmounts: [],
                defaultFacAmounts: [{id: 0, name: 'None'},{id: 1.25, name: '1,25%'},{id: 2.5, name: '2,5%'},{id: 5, name: '5%'},{id: -1, name: 'Specify...'}],
                vinModeTypes: [{id: 0, name: 'Do not show VINs'}, {id: 1, name: 'Show VINs'}, {id: 2, name: 'Show VINs + description'}],
                defaultFacTypeAmounts: [{id: 0, name: 'Credit FAC'},{id: 1, name: 'Only FAC'}],
                SCalcUnitFields: ['sales_rate', 'sales_rate_currency_id'],
                CCalcUnitFields: ['collect', 'sales_rate_currency_id'],
                addressOptions: [],
                vatAmounts: [{id: 0, name: '0%'}],
                office: {id: null, relation: {name: ''} },
                projectService: projectService,
                customerService: customerService,
                shiploadMode: 0,
                defaultCurrency: 1,
                mustRecalculateTotals: false,
                prevInvoiceCurr: 0,
                canEditCompany: false,
                customerIsNaturalPerson: false,
                noEdit: false,
                loading: false,
                preloading: false,
                isEditing: false,
                invalid: {},
                dataItemsLoaded: 0,
                dataItemsToLoad: 4,
                formActive: false
            }
        },
        watch: {
            itemData: function (val) { this.invoice = val },
        },
        computed: {
            sendButtonDisabled() { return !(this.emailData.to && this.emailData.subject); },
            customerContent() { return this.invoice.customer ? (this.invoice.customer.relation.name + (this.invoice.customer.relation.country ? (', ' + this.invoice.customer.relation.country.iso) : '')) : ''; },
            canEditInvoices(){ return store.getters.canEditInvoices; }
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(isForPDF = false){
                this.invalid = {};
                let valid = true;

                if(this.invoice.invoice_lines.length == 0){
                    alert(this.$t('errors.no_invoice_lines'));
                    return false;
                }

                let reqfields = '#invoiceoptions input:required, #invoiceoptions select:not([disabled]), .transport-unit-table input:not(.multi-list):not(.optional), .transport-unit-table select, .description';
                if(this.jobItem) reqfields = '#jobTabContent #invoiceoptions input:required, #jobTabContent #invoiceoptions select:not([disabled]), #jobTabContent #invoices-popup .transport-unit-table input:not(.multi-list):not(.optional), #jobTabContent #invoices-popup .transport-unit-table select, #jobTabContent #invoices-popup .description';

                let required = document.querySelectorAll(reqfields);

                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select')) {
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(!valid) alert(this.$t('errors.empty_fields'));
                else if(isForPDF) return true;
                else{
                    this.invoice.total_exl_vat = this.totals.grandsub.replace(/[A-Za-z]/g, '').replace('.', '').replace(',', '.');
                    this.invoice.total_inc_vat = this.totals.grand.replace(/[A-Za-z]/g, '').replace('.', '').replace(',', '.');
                    this.invoice.invoice_lines.forEach((line, index) => {
                        if(line.sales_rate_calculation_unit_id == 1 && line.amount !== 0)
                            line.amount = 1;
                        if(!line.sales_rate)
                            line.sales_rate = 0;
                        if(line.quantity === null || line.quantity === "")
                            line.quantity = 0;
                        line.order_by = index;
                    });
                    let rates = [];
                    for(var key in this.invoice.exchange_rate){
                        if(this.totals.sub[key]){
                            rates.push({
                                'currency_id': key, 
                                'exchange_rate': this.invoice.exchange_rate[key].display_value ? this.invoice.exchange_rate[key].display_value : this.invoice.exchange_rate[key].value,
                                'invoice_id': this.isReadyInvoice ? null : this.invoice.id, 
                                'id': this.invoice.exchange_rate[key].id
                            });
                        }
                    }
                    this.invoice.exchange_rates = rates;
                    this.invoice.is_pro_forma = false;

                    if(this.isReadyInvoice){
                        this.invoice.voyage = this.voyageNumber;
                        this.invoice.sales_manager = this.invoice.sales_manager_names;
                        this.invoice.job_number = this.invoice.job_numbers;
                    }
                    if(this.isFromShipload){
                        this.invoice.checked_shipload_ids = this.invoice.invoice_lines.filter(line => line.legacy_bl_id).map(line => line.legacy_bl_id);
                    }
                    if(!this.invoice.is_finalized){
                        this.invoice.is_finalized = 0;
                        this.invoice.is_mailed = 0;
                        this.invoice.is_exported = 0;
                    }
                }

                return valid;
            },
            getData() {
                return this.invoice;
            },
            setData(data) {
                this.isEditing = true;
                this.preloading = true;
                this.legacyExchangeRates = {};
                if(this.shiploadMode){
                    this.setDataShipload(data);
                    return;
                }
                console.log('start', data);
                if(data){
                    this.invoice = data;
                    this.prevInvoiceCurr = data.currency_id;
                    this.isVATRequired = true;
                    this.isReadyInvoice = data.sales_manager_names !== undefined;
                    this.isApproved = data.is_finalized;
                    if(!this.jobItem){
                        document.getElementById('detailsSpan').innerHTML = ": " + data.number;
                        document.getElementById('finishbutton').disabled = (!data.id || data.is_finalized);
                    }
                    else{
                        document.getElementById('xfinishbutton').disabled = (!data.id || data.is_finalized);
                        document.getElementById('xemailbutton').disabled = !data.id;
                    }
                    if(this.invoice.fac) this.invoice.fac = Number(this.invoice.fac);
                    if(!data.invoice_type_id) this.invoice.invoice_type_id = null;
                    if(this.isReadyInvoice){
                        this.voyageNumber = this.invoice.vessel.name + ' ' + this.invoice.number;
                        this.invoice.number = '';
                        this.invoice.date = moment().format('YYYY-MM-DD');
                        this.invoice.vat_type_id = 1;
                        this.invoiceParent = this.invoice.jobs[0].invoices[0];
                        if(this.invoiceParent){
                            this.invoice.office_id = this.invoiceParent.office_id;
                            this.invoice.customer_id = this.invoiceParent.customer_id;
                        }
                        else{
                            this.isReadyInvoice = false;
                        }
                    }
                    else{
                        this.invoice.invoice_lines.forEach(e => e.amount = Number(e.amount));
                    }
                    if(this.jobItem && this.invoice.is_finalized){
                        this.$nextTick(() => {
                            const el = document.querySelector('#jobTab .job-nav-button.active');
                            if(el && el.innerHTML != data.number) {
                                this.afterFinalization(data.number);
                            }
                        });
                    }
                    this.loadData();
                    if(!this.invoice.port_of_loading_date && this.invoice.port_of_loading) 
                        this.checkForPOLDate();
                    return;
                }
                this.preloading = false;
                this.afterLoadData();
                
                this.setDefaultEmailData();

                this.getCustomerAddress();
                this.targetCurrency = this.currencies.find(curr => curr.id == this.invoice.currency_id);
                this.companyCurrency = this.currencies.find(curr => curr.code == 'EUR');

                let rates = {};
                this.voyageNumber = this.invoice.voyage;
                this.invoice.exchange_rates.forEach((rate) => {
                    rates[rate.currency_id] = {};
                    rates[rate.currency_id].value = rate.exchange_rate;
                    rates[rate.currency_id].id = rate.id;
                    rates[rate.currency_id].display_value = rate.exchange_rate;
                });
                this.invoice.exchange_rate = rates;

                this.invoice.job_numbers = '';
                this.invoice.our_vat_id = this.invoice.office.relation.vat;
                this.invoice.customer_vat_id = this.invoice.customer.relation.vat;
                this.invoice.customer_address = '';
                this.setEmailData(this.invoice);
                if(this.invoice.mainport_project && this.invoice.mainport_project.mainport_project_exchange_rates){
                    this.setLegacyExchangeRates(this.invoice.mainport_project);
                }
                this.getVatPercentages();
                this.dataItemsLoaded = 0;

                let curr = this.currencies.find(tc => tc.id == this.prevInvoiceCurr);
                curr = curr ? curr.code : '';
                if(!this.invoice.exchange_rate[this.prevInvoiceCurr]){
                    const rate = this.invoice.exchange_rates[this.companyCurrency.id] ? this.invoice.exchange_rates[this.companyCurrency.id] : Object.values(this.invoice.exchange_rates)[0];
                    this.invoice.exchange_rate[this.prevInvoiceCurr] = { display_value: rate ? rate.exchange_rate : '' };
                }
                if(this.invoice.is_finalized && Object.keys(this.invoice.exchange_rate).length == 1){
                    console.log(this.invoice.exchange_rate, this.targetCurrency.code);
                    if(!this.invoice.exchange_rate[1]){
                        const rate = Object.values(this.invoice.exchange_rates)[0];
                        this.invoice.exchange_rate[1] = { display_value: rate ? rate.exchange_rate : '' };
                    }
                    else{
                        const rates = Object.values(this.invoice.exchange_rates)[0];
                        let rate = 1;
                        if(rates && rates.exchange_rate){
                            rate = (1.00 / Number(rates.exchange_rate)).toFixed(4);
                        }
                        else{
                            rate = rate.toFixed(4);
                        }
                        this.invoice.exchange_rate[2] = { display_value: rate };
                    }
                }
            },
            setDataShipload(data) {
                if(data){
                    this.isReadyInvoice = false;
                    this.isFromShipload = true;
                    this.isVATRequired = true;
                    let showCollect = false;
                    if(this.shiploadMode == 'customer' || this.shiploadMode == 'company'){
                        for(let unit of data.units){
                            if(unit.totalcollect && unit.totalcollect > 0){
                                showCollect = true;
                                break;
                            }
                        }
                    }
                    if(!this.jobItem) this.setLegacyExchangeRates(data);
                    if(this.noEdit){
                        this.invoice = {...data};
                    }
                    else
                        this.setInvoiceObj(data, this.jobItem ? {} : this.legacyExchangeRates, showCollect, this.jobItem);
                    this.legacyExchangeRates = [];
                    this.setDefaultEmailData();
                    this.voyageNumber = data.voyage.vessel ? (data.voyage.vessel.name + ' ' + data.voyage.number) : data.voyage;
                    if(this.shiploadMode == 'repr'){
                        this.invoice.invoice_lines.push({
                            quantity: 1,
                            line_type: null,
                            description: "Charge you for disbursements on the above mentioned sailing\n",
                            description_vins: null,
                            sales_rate: null,
                            amount: 0,
                            buying_rate: '',
                            description_vins_header: null,
                            buying_rate_currency_id: null,
                            buying_rate_calculation_unit_id: 1,
                            sales_rate_currency_id: null,
                            sales_rate_calculation_unit_id: 1,
                            supplier_id: null,
                            vat: '0',
                            collect: null,
                            collect_currency_id: null,
                            surcharges: []
                        });
                    }
                    let addInvoiceLineShipload = function(unit, description, vins, showCollect, shiploadMode, invoice){
                        invoice.invoice_lines.push({
                            quantity: shiploadMode == 'repr' ? 1 : Math.max(1, unit.units.length),
                            line_type: null,
                            description: description,
                            description_vins: vins ? vins : null,
                            sales_rate: shiploadMode == 'repr' ? unit : null,
                            amount: 1,
                            buying_rate: '',
                            description_vins_header: shiploadMode != 'repr' ? unit.headline : null,
                            buying_rate_currency_id: null,
                            buying_rate_calculation_unit_id: 1,
                            sales_rate_currency_id: null,
                            sales_rate_calculation_unit_id: 1,
                            supplier_id: null,
                            legacy_bl_id: shiploadMode != 'repr' ? unit.id : null,
                            vat: '0',
                            collect: showCollect ? (unit.totalcollect ? unit.totalcollect : 0) : null,
                            collect_currency_id: showCollect ? 1 : null,
                            surcharges: []
                        });
                    }
                    if(this.shiploadMode == 'customer' || this.shiploadMode == 'company'){
                        data.units.forEach(unit => {
                            let vinArray = [];
                            unit.units.forEach(unitDetails => vinArray.push(unitDetails.chassisnumber));
                            if(vinArray.length > 0){
                                this.invoice.show_vins_type = 1;
                            }
                            let vins = vinArray.join('\n');
                            addInvoiceLineShipload(unit, unit.number, vins, showCollect, this.shiploadMode, this.invoice);
                        });
                    }
                    else if(this.shiploadMode == 'repr'){
                        for(let v in data.extraDetails.voyages){
                            addInvoiceLineShipload(data.extraDetails.voyages[v], v, null, showCollect, this.shiploadMode, this.invoice);
                        }
                    }
                    else if(this.jobItem){
                        this.getCustomerAddress();
                        if(data.voyage.id && !this.invoice.mainport_project_id){
                            console.log('find project');
                            projectService.find(data.voyage.id, data.office.id).then(response => {
                                if(!response.data.id) return;
                                this.invoice.mainport_project = response.data;
                                this.invoice.mainport_project_id = response.data.id;
                                this.setLegacyExchangeRates(response.data);
                                this.invoice.exchange_rate = this.legacyExchangeRates;
                                this.checkForPOLDate();
                            });
                        }
                        this.shiploadMode = 'job';
                    }
                    this.office = {...data.office};
                    this.loadData();
                    return;
                }
                this.preloading = false;
                if(!this.formActive) return;
                this.afterLoadData();

                this.targetCurrency = this.currencies.find(currx => currx.id == (this.noEdit ? this.invoice.currency_id : this.defaultCurrency));
                this.companyCurrency = null;
                const companyCountry = this.invoice.office.relation.country_id;
                for(let curr of this.currencies){
                    for(let country of curr.countries){
                        if(country.id == companyCountry){
                            this.companyCurrency = curr;
                            break;
                        }
                    }
                    if(this.companyCurrency) break;
                }
                if(!this.companyCurrency)
                    this.companyCurrency = this.currencies.find(curr => curr.code == 'EUR');
                if(!this.companyCurrency)
                    this.companyCurrency = this.targetCurrency;
                this.invoice.currency_id = this.noEdit ? this.invoice.currency_id : this.defaultCurrency;
                if(!this.jobItem){
                    this.invoice.invoice_lines.forEach(line => {
                        line.buying_rate_currency_id = this.defaultCurrency;
                        line.sales_rate_currency_id = this.defaultCurrency;
                    });
                }
                this.getVatPercentages();
                this.checkForPOLDate();
                console.log(this.invoice);
            },
            reset(isEditing = false) {
                this.isEditing = isEditing;
                this.addressOptions = [];
                this.prevInvoiceCurr = 0;
                this.isApproved = false;
                this.isVATRequired = true;
                this.loading = false;
                this.preloading = true;
                this.customerIsNaturalPerson = false;
                this.invalid = {};
                this.legacyExchangeRates = {};
                this.totals = {'sub': {}, 'vat': {}, 'grand': '-', 'grandsub': '-'}
                if(this.dataItemsLoaded < this.dataItemsToLoad && !isEditing && !this.jobItem){
                    document.getElementById(this.jobItem ? 'xfinishbutton' : 'finishbutton').disabled = true;
                    this.invoice.quote_general_costs = [];
                    this.loadData();
                    return;
                }
                if(!this.formActive) return;
                this.preloading = false;
                this.setInvoiceObj();
                this.setDefaultEmailData();
                if(!this.isEditing && !this.jobItem){
                    this.afterLoadData();
                }
            },
            setInvoiceObj(data = null, exchangeRates = {}, showCollect = false, isJobItem = false){
                this.invoice = {
                    number: '',
                    office_id: data ? data.office.id : store.getters.getCompanyId,
                    office: data ? data.office : null,
                    customer_id: isJobItem ? data.customer_id : null,
                    customer: isJobItem ? data.customer : undefined,
                    customer_address_id: 0,
                    date: moment().format('YYYY-MM-DD'),
                    currency_id: data ? null : this.defaultCurrency,
                    address: '',
                    country_id: null,
                    department: '',
                    customer_vat_id: isJobItem ? data.customer.relation.vat : '',
                    our_vat_id: data ? data.office.relation.vat : '',
                    vat_type_id: 1,
                    payment_terms_id: null,
                    fac: 0,
                    fac_manual: null,
                    fac_type: 0,
                    total_exl_vat: 0,
                    total_inc_vat: 0,
                    is_project: 1,
                    invoice_type_id: null,
                    customer_reference: '',
                    exchange_rate: exchangeRates,
                    invoice_lines: isJobItem ? data.invoice_lines : [],
                    sales_managers: [],
                    voyage: data ? (data.voyage.vessel ? (data.voyage.vessel.name + ' ' + data.voyage.number) : data.voyage) : '',
                    port_of_loading: data ? data.port_name : '',
                    port_of_discharge: data ? data.pod_name : '',
                    sales_manager: '',
                    job_number: isJobItem ? this.$parent.job.number : '',
                    job_numbers: '',
                    mainport_project_id: data && !isJobItem ? data.id : null,
                    mainport_project: data && !isJobItem ? {name: data.name + ' (' + data.number + ')'} : null,
                    checked_shipload_ids: data ? data.bl_ids : null,
                    show_collect: showCollect,
                    show_vins_type: 0,
                    client_notice: '',
                    job_booking_id: isJobItem ? data.job_booking_id : null,
                    id: null
                };
            },
            afterLoadData(){
                let office = this.companies.find(comp => comp.id == store.getters.getCompanyId);
                this.canEditCompany = !store.getters.getCompanyId;
                this.companyCurrency = null;
                const companyCountry = office ? office.country_id : null;
                for(let curr of this.currencies){
                    for(let country of curr.countries){
                        if(country.id == companyCountry){
                            this.companyCurrency = curr;
                            break;
                        }
                    }
                    if(this.companyCurrency) break;
                }
                if(!this.companyCurrency) {
                    this.companyCurrency = this.currencies.find(curr => curr.code == 'USD');
                }
                this.targetCurrency = this.currencies.find(curr => curr.id == this.invoice.currency_id);
                if(this.invoice.invoice_lines.length == 0){
                    this.addInvoiceLine(0, false);
                }
                this.getVatPercentages();
                let required = document.querySelectorAll('#invoiceoptions input:not(.multi-list):not(.optional), #invoiceoptions select, .transport-unit-table input:not(.multi-list):not(.optional), .transport-unit-table select, .description');
                required.forEach(el => el.classList.remove('is-invalid'));
                window.setTimeout(() => { 
                    if(!this.invoice.customer_id){
                        const el = document.querySelector('#customer_id_wrapper > input');
                        if(el){
                            el.previousElementSibling.click();
                            el.focus();
                        }
                    }
                }, 700);
                
                if(this.jobItem){
                    document.getElementById('xfinishbutton').disabled = (!this.invoice.id || this.invoice.is_finalized);
                    document.getElementById('xemailbutton').disabled = !this.invoice.id;
                }
            },
            setEmailData(invoice){
                let office = invoice.office.relation.name;
                this.emailFromDisplay = 'noreply@mg.nmtshipping.com <Accounting Dept. - ' + office + '>';
                this.emailData.subject = 'Your NMT Shipping invoice: ' + invoice.number;
                if(invoice.office.finance_setting.invoice_email_template){
                    let template = invoice.office.finance_setting.invoice_email_template;
                    this.emailData.body = template.replaceAll('#CUSTOMER_NAME#', invoice.customer.relation.name).replaceAll('#COMPANY_NAME#', office).replaceAll('#INVOICE_NUMBER#', invoice.number);
                }
                else{
                    this.emailData.body = `Dear ${invoice.customer.relation.name},\n\nPlease see attached invoice ${invoice.number} from ${office}.\nDon’t hesitate to reach out if you have any questions.\n\nKind regards,\n\n${office}\nAccounting Department`;
                }
                //For staging only
                if(window.location.href.includes('netvibestest')){
                    if(invoice.office.finance_setting.invoice_email)
                        this.emailData.reply_to = invoice.office.finance_setting.invoice_email ? 'support.online+' + invoice.office.finance_setting.invoice_email.replace('@', '.') + '@netvibes.nl' : '';
                    else if(invoice.office.relation.addresses[0] && invoice.office.relation.addresses[0].email)
                        this.emailData.reply_to = 'support.online+' + invoice.office.relation.addresses[0].email.replace('@', '.') + '@netvibes.nl';
                    if(invoice.customer.email_customer)
                        this.emailData.to = 'support.online+' + invoice.customer.email_customer.replace('@', '.') + '@netvibes.nl';
                }
                else{
                    if(invoice.office.finance_setting.invoice_email)
                        this.emailData.reply_to = invoice.office.finance_setting.invoice_email;
                    else if(invoice.office.relation.addresses[0] && invoice.office.relation.addresses[0].email)
                        this.emailData.reply_to = invoice.office.relation.addresses[0].email;
                    this.emailData.to = invoice.customer.email_customer;
                }
                if(this.emailData.reply_to){
                    this.emailData.body += `\n\nE-mail: ${this.emailData.reply_to}`;
                    this.emailData.cc = this.emailData.reply_to;
                }
                if(invoice.attachments){
                    this.emailData.attachments = invoice.attachments;
                }
            },
            addInvoiceLine(index, setFocus = true, amountZero = false, description = null){
                const item = {
                    quantity: '',
                    line_type: null,
                    description: description ? description : '',
                    sales_rate: null,
                    amount: amountZero ? 0 : 1,
                    buying_rate: '',
                    buying_rate_currency_id: this.defaultCurrency,
                    buying_rate_calculation_unit_id: 1,
                    sales_rate_currency_id: this.invoice.currency_id,
                    sales_rate_calculation_unit_id: 1,
                    collect: '',
                    collect_currency_id: this.invoice.currency_id,
                    supplier_id: null,
                    vat: '0',
                    calc_fac: 0,
                    surcharges: []
                };
                let newIndex = 0;
                if(typeof index === 'number'){
                    this.invoice.invoice_lines.splice(index + 1, 0, item);
                    newIndex = index + 1;
                }
                else{
                    newIndex = this.invoice.invoice_lines.push(item) - 1;
                }
                if(setFocus)
                    this.$nextTick(() => document.querySelector('#invoice-line-' + newIndex + ' > textarea').focus());
            },
            copyInvoiceLine(index, sindex = null){
                const item = sindex === null ? this.invoice.invoice_lines[index] : this.invoice.invoice_lines[index].surcharges[sindex];
                let newEl = JSON.parse(JSON.stringify(item));
                newEl.id = null;
                if(sindex === null){
                    this.invoice.invoice_lines.splice(index, 0, newEl);
                    this.invoiceOnChange();
                    this.$nextTick(() => document.querySelector('#invoice-line-' + (index + 1) + ' > textarea').focus());
                }
                else{
                    this.invoice.invoice_lines[index].surcharges.splice(sindex, 0, newEl);
                    this.invoiceOnChange();
                    this.$nextTick(() => document.querySelector('#invoice-line-' + index + '-s-' + (sindex + 1) + ' > textarea').focus());
                }
            },
            removeInvoiceLine(index, sindex = null){
                if(!this.invoice.invoice_lines[index].description || sindex != null || confirm(this.$t('form.are_you_sure_delete') + ' ' + this.invoice.invoice_lines[index].description + '?')){
                    if(sindex === null)
                        this.invoice.invoice_lines.splice(index, 1);
                    else
                        this.invoice.invoice_lines[index].surcharges.splice(sindex, 1);
                    this.invoiceOnChange();
                }
            },
            addSurcharge(index){
                const newEl = {
                    quantity: '1',
                    line_type: 1,
                    description: '',
                    sales_rate: null,
                    amount: 1,
                    buying_rate: '',
                    buying_rate_currency_id: this.defaultCurrency,
                    buying_rate_calculation_unit_id: 1,
                    sales_rate_currency_id: this.invoice.currency_id,
                    sales_rate_calculation_unit_id: 5,
                    collect: '',
                    collect_currency_id: this.invoice.currency_id,
                    supplier_id: null,
                    vat: '0'
                };
                if(!this.invoice.invoice_lines[index].surcharges){
                    this.invoice.invoice_lines[index].surcharges = [];
                }
                const sIndex = this.invoice.invoice_lines[index].surcharges.push(newEl) - 1;
                this.invoiceOnChange();
                this.$nextTick(() => document.querySelector('#invoice-line-' + index + '-s-' + sIndex + ' > textarea').focus());
            },
            addDescVINs(item){
                item.description_vins = '';
                if(!this.invoice.show_vins_type){
                    this.invoice.show_vins_type = 1;
                }
            },
            setDescriptionVins(){
                if(this.shiploadMode || this.isEditing){
                    this.invoice.invoice_lines.forEach(line => {
                        if(line.description && line.description_vins_header){
                            if(this.invoice.show_vins_type === 2){
                                const temparr = line.description.split('\n');
                                if(temparr.at(-1) == line.description_vins_header) return;
                                temparr.push(line.description_vins_header);
                                line.description = temparr.join('\n');
                            }
                            else if(this.invoice.show_vins_type === 1){
                                const temparr = line.description.split('\n');
                                if(temparr.at(-1) != line.description_vins_header) return;
                                temparr.pop();
                                line.description = temparr.join('\n');
                            }
                        }
                    });
                }
            },
            onInvoiceTypeChange(){
                if(this.invoice.invoice_type_id == 1){
                    if(this.invoice.invoice_lines[0] && this.invoice.invoice_lines[0].description && !confirm("This will overwrite the current invoice lines. Are you sure?")){
                        this.invoice.invoice_type_id = null;
                        return;
                    }
                    this.invoice.invoice_lines = [];
                    this.addInvoiceLine(0, false, true, 'Charge you for disbursements on the above mentioned');
                    this.addInvoiceLine(1, false, false, this.invoice.port_of_loading + ' - ' + this.invoice.port_of_discharge);
                    this.invoice.invoice_lines[1].quantity = 1;
                    this.invoice.invoice_lines[1].sales_rate = this.totals.granddata.total;
                    this.invoice.invoice_lines[1].sales_rate_currency_id = this.totals.granddata.currency_id;
                    this.invoiceOnChange();
                }
            },
            findCustomerRef(){
                if(this.invoice.customer_reference) return;
                invoiceService.findReference(this.invoice.job_number).then(response =>  this.invoice.customer_reference = response.data.ref).catch(error => console.log('error', error) );
            },
            getInvoiceGrandTotal(){
                let subtotal = 0;
                this.totals.fac = {};
                this.totals.sub = {};
                this.totals.vat = {};
                let vatSubTotals = [];
                let grandTotal = 0;
                this.vatAmounts.forEach(amount => { if(amount.id != 0) vatSubTotals[amount] = 0 } );
                //Ensures exchange rate in EUR
                const lines = [...this.invoice.invoice_lines];
                if(this.invoice.currency_id != 1 && !lines.find(il => il.sales_rate_currency_id == 1)){
                    lines.push({
                        sales_rate_calculation_unit_id: 1,
                        sales_rate: 0,
                        sales_rate_currency_id: 1,
                        amount: 1,
                        collect: 0,
                        quantity: 0
                    });
                }
                const calcLines = [];
                lines.forEach(line => {
                    if(line.sales_rate_calculation_unit_id != 1 && line.amount == null)
                        line.amount = 1;
                    if(line.amount == 0)
                        return;
                    calcLines.push(line);
                    if(line.surcharges){
                        line.surcharges.forEach(surcharge => {
                            const newSurcharge = {...surcharge};
                            newSurcharge.vat = line.vat;
                            calcLines.push(newSurcharge);
                        });
                    }
                });
                let prevNonSurcharge = 0;
                calcLines.forEach(el => {
                    let rawRate = ((el.sales_rate_calculation_unit_id == 6 ? 1 : el.quantity) * el.sales_rate * (el.sales_rate_calculation_unit_id == 1 ? 1 : el.amount));
                    rawRate = Math.round(rawRate * 100) / 100;
                    if(el.line_type != 1)
                        prevNonSurcharge = {rate: rawRate + 1 - 1, currency_id: el.sales_rate_currency_id};
                    else if(el.sales_rate_calculation_unit_id == 5){
                        rawRate = Math.round((prevNonSurcharge.rate * el.sales_rate * 0.01) * 100) / 100;
                        el.sales_rate_currency_id = prevNonSurcharge.currency_id;
                    }

                    if(this.invoice.show_collect && el.collect)
                        rawRate -= el.collect

                    let thisSubTotal = this.calculateRoe(el, rawRate);
                    if(this.invoice.fac && el.calc_fac) {
                        let fac_rate = this.invoice.fac;
                        if(fac_rate == -1)
                            fac_rate = this.invoice.fac_manual;
                        console.log("fac_rate", fac_rate, "el.calc_fac", el.calc_fac, "invoice.fac_type", this.invoice.fac_type);
                        let fac = this.calculateRoe(el, (rawRate / 100 * fac_rate) * -1);

                        if(this.invoice.fac_type == 0)
                            thisSubTotal[1] += fac[1];
                        else
                            thisSubTotal[1] = fac[1];
                        if(this.totals.fac[el.sales_rate_currency_id]){
                            this.totals.fac[el.sales_rate_currency_id].total += ((rawRate / 100 * fac_rate) * -1);
                        }
                        else{
                            this.totals.fac[el.sales_rate_currency_id] = {
                                total: ((rawRate / 100 * fac_rate) * -1),
                                code: this.currencies.find(curr => curr.id == el.sales_rate_currency_id).code,
                                id: 0
                            };
                        }
                    }
                    subtotal += thisSubTotal[1];

                    this.totals.sub = this.addExRateTotals(this.totals.sub, thisSubTotal, rawRate, el.sales_rate_currency_id);
                    if(el.vat){
                        if(this.totals.vat[el.vat]){
                            this.totals.vat[el.vat].total += thisSubTotal[1];
                            this.totals.vat[el.vat].raw += thisSubTotal[1];
                        }
                        else{
                            this.totals.vat[el.vat] = {
                                total: thisSubTotal[1],
                                roe: '',
                                roeraw: '',
                                code: this.targetCurrency.code,
                                raw: thisSubTotal[1]
                            }
                        }
                    }
                });
                const currencySuffix = ' ' + this.targetCurrency.code;
                for(let cstIndex in this.totals.fac) {
                    let item = this.totals.fac[cstIndex];
                    item.total = this.formatCurrency(item.total) + ' ' + item.code;
                }
                for(let cstIndex in this.totals.sub) {
                    let item = this.totals.sub[cstIndex];
                    item.hide = item.total == 0;
                    item.total = this.formatCurrency(item.total) + currencySuffix;
                    item.raw = this.formatCurrency(item.raw) + ' ' + item.code;
                }
                if(this.invoice.is_finalized && this.totals.sub[1] && !this.totals.sub[2]){
                    this.totals.sub[1] = {total: 0, code: 'USD', id: 2, hide: true};
                }
                if(this.invoice.is_finalized && this.totals.sub[2] && !this.totals.sub[1]){
                    this.totals.sub[1] = {total: 0, code: 'EUR', id: 1, hide: true};
                }
                let totalVAT = 0;
                for(let vstIndex in this.totals.vat) {
                    let item = this.totals.vat[vstIndex];
                    const vst = item.total;
                    const vstTotal = (vst * (vstIndex / 100));
                    item.total = this.formatCurrency(vstTotal) + currencySuffix;
                    item.raw = this.formatCurrency(item.raw) + ' ' + item.code;
                    if(this.invoice.vat_type_id == 3)
                        totalVAT += vstTotal;
                }
                grandTotal = (Math.round(subtotal * 100) / 100) +  (Math.round(totalVAT * 100) / 100);
                this.totals.grand = this.formatCurrency(grandTotal) + currencySuffix;
                this.totals.granddata = {total: grandTotal + '', currency_id: this.targetCurrency.id};
                this.totals.grandsub = this.formatCurrency(subtotal) + currencySuffix;
                this.totals.code = this.targetCurrency.code;
                if(this.mustRecalculateTotals){
                    this.mustRecalculateTotals = false;
                    this.$nextTick(() => {
                        this.getInvoiceGrandTotal();
                        this.$forceUpdate();
                    })
                }
            },
            calculateRoe(item, value){
                let exRate = 1;
                if(item.sales_rate_currency_id == this.targetCurrency.id){
                    return [exRate, value, this.targetCurrency.code];
                }
                const currency = this.currencies.find(curr => curr.id == item.sales_rate_currency_id);
                try{
                    const nval = Number(this.invoice.exchange_rate[item.sales_rate_currency_id].value);
                    if(this.targetCurrency.code == 'EUR')
                        exRate = nval;
                    else if(item.sales_rate_currency_id == 1)
                        exRate = this.isFromShipload ? nval : (1 / nval);
                    else
                        exRate = (1 / this.invoice.exchange_rate[1].value) * nval;
                }
                catch(e){ this.addInvoiceExRate(item, exRate, currency) }
                return [exRate, exRate * value, currency.code];
            },
            addInvoiceExRate(item, exRate, currency){
                try{
                    if(this.legacyExchangeRates[item.sales_rate_currency_id] !== undefined){
                        const nval = Number(exRate);
                        if(!this.invoice.exchange_rate[item.sales_rate_currency_id]){
                            this.invoice.exchange_rate[item.sales_rate_currency_id] = {...this.legacyExchangeRates[item.sales_rate_currency_id]};
                            this.$nextTick(() => {
                                this.getInvoiceGrandTotal();
                                this.$forceUpdate();
                            });
                            return;
                        }
                        this.invoice.exchange_rate[item.sales_rate_currency_id].value = nval;
                        this.invoice.exchange_rate[item.sales_rate_currency_id].display_value = nval;
                    }
                    else{
                        exRate = this.targetCurrency.currency_rates.exchange_rate_eur / currency.currency_rates.exchange_rate_eur;
                        if(this.invoice.exchange_rate === undefined){
                            this.invoice.exchange_rate = {}
                        }
                        const nval = Number(exRate);
                        this.invoice.exchange_rate[item.sales_rate_currency_id] = {};
                        this.invoice.exchange_rate[item.sales_rate_currency_id].value = nval.toFixed(4);
                        this.invoice.exchange_rate[item.sales_rate_currency_id].display_value = (item.sales_rate_currency_id != this.companyCurrency.id ? nval : (1/nval)).toFixed(4);
                        this.invoice.exchange_rate[item.sales_rate_currency_id].id = null;
                        this.mustRecalculateTotals = true;
                    }
                }
                catch(e){ console.log('error', e) }
            },
            addExRateTotals(totalContainer, rates, rawRate, currId){
                let item = totalContainer;
                if(item[currId]){
                    item[currId].total += rates[1];
                    item[currId].raw += rawRate;
                }
                else{
                    let roe = "";
                    if(this.targetCurrency.code == 'EUR' && this.invoice.exchange_rate[currId])
                        roe = Number(this.invoice.exchange_rate[currId].value);
                    else if(currId == 1 && this.invoice.exchange_rate[currId])
                        roe = Number(this.invoice.exchange_rate[1].value);
                    else
                        roe = rates[0];
                    let roeraw = currId != this.companyCurrency.id ? roe : (1 / roe);

                    item[currId] = {
                        total: rates[1],
                        roe: roe.toFixed(4).replace('.', ','),
                        roeraw: roeraw.toFixed(4).replace('.', ','),
                        code: rates[2],
                        raw: rawRate,
                        id: currId
                    }
                }
                return item;
            },
            setProjectData(id){
                projectService.show(id).then(response => {
                    this.invoice.voyage = response.data.voyage ? (response.data.voyage.vessel.name + ' ' + response.data.voyage.number) : response.data.voyage_manual;
                    this.setLegacyExchangeRates(response.data);
                    for(let currency_id in this.legacyExchangeRates){
                        this.invoice.exchange_rate[currency_id] = {...this.legacyExchangeRates[currency_id]};
                    }
                }).catch(error => console.log('error', error) );
            },
            setLegacyExchangeRates(data){
                this.legacyExchangeRates = {};
                data.mainport_project_exchange_rates.forEach(er => {
                    const nvalue = Number(er.exchange_rate);
                    this.legacyExchangeRates[er.currency_id] = {
                        value: Number(er.exchange_rate),
                        display_value: er.exchange_rate != 1 ? nvalue.toFixed(4) : (1 / nvalue).toFixed(4)
                    };
                });
                if(!this.legacyExchangeRates[1] && data.mainport_project_exchange_rates.length > 0){
                    const firstLegacyRate = Object.values(this.legacyExchangeRates)[0];
                    this.legacyExchangeRates[1] = {
                        value: firstLegacyRate.value.toFixed(4) == firstLegacyRate.display_value ? Number((1 / firstLegacyRate.value).toFixed(4)) : firstLegacyRate.value,
                        display_value: firstLegacyRate.display_value
                    };
                }
            },
            setCasing(value){
                let voyageManualSplit = this.invoice[value].split(' ');
                voyageManualSplit.forEach((part, index) => {
                    if(!part.match(/^[A-Z]+[0-9]+$/) && part[0]){
                        voyageManualSplit[index] = part[0].toUpperCase() + part.substring(1).toLowerCase();
                    }
                });
                this.invoice[value] = voyageManualSplit.join(' ');
            },

            loadData(){
                if(this.jobItem && this.companies[0]){
                    this.dataItemsLoaded = this.dataItemsToLoad + 1;
                    this.checkData();
                    return;
                }
                this.countries = dataStore.getters.getCountryNames;
                currencyService.indexWithRates().then(response => {
                    this.currencies = response.data;
                    this.defaultCurrency = this.currencies.filter(currency => currency.is_default == 1)[0].id;
                    this.checkData();
                }).catch(error => console.log('error', error) );
                relationService.index(null,null,'office').then(response => {
                    this.companies = response.data //todo
                    this.checkData();
                }).catch(error => console.log('error', error) );
                paymentTermService.index().then(response => {
                    this.paymentTerms = response.data;
                    this.paymentTerms.forEach(term => {
                        term.name = term.days == 0 ? 'Payment Against Documents' : (term.days + ' days');
                    });
                    this.checkData();
                }).catch(error => console.log('error', error) );
                calcUnitService.index().then(response => {
                    this.calcUnits = response.data.filter(cu => cu.name != 'flat fee');
                    this.calcUnits.unshift({id: null, name: 'x'});
                    this.calcUnitsNoPerc = response.data.filter(cu => cu.name != 'flat fee' && cu.name != '%');
                    this.calcUnitsNoPerc.unshift({id: null, name: 'x'});
                    this.checkData();
                }).catch(error => console.log('error', error) );
            },
            
            getCustomerAddress(setIds = false){
                customerService.showForInvoice(this.invoice.customer_id).then(response => {
                    if(response.data){
                        this.invoice.client_notice = response.data.client_notice;
                        if(response.data.default_contact_person) this.invoice.department = response.data.default_contact_person;
                        if(setIds){
                            this.invoice.customer_vat_id = response.data.relation.vat;
                            this.invoice.payment_terms_id = response.data.payment_terms_id;
                        }
                        if(this.gettingCustomerInfo){
                            if(response.data.currency_id){
                                this.invoice.currency_id = this.noEdit ? this.invoice.currency_id : response.data.currency_id;
                                this.defaultCurrency = response.data.currency_id;
                                if(!this.isEditing && this.invoice.invoice_lines[0] && !this.invoice.invoice_lines[0].sales_rate){
                                    this.invoice.invoice_lines[0].sales_rate_currency_id = this.defaultCurrency;
                                }
                                this.targetCurrency = this.currencies.find(curr => curr.id == response.data.currency_id);
                            }
                            this.invoiceOnChange();
                        }
                        this.setCustomerAddress(response.data.relation.addresses, response.data.relation.type == "company");
                        this.gettingCustomerInfo = false;
                    }
                }).catch(error => console.log('error', error) );
            },
            setCustomerAddress(addresses, isCompany){
                if(!addresses[0]) return;
                let address = addresses.find(add => (this.invoice.customer_address_id ? (add.id == this.invoice.customer_address_id) : (add.is_hq == 1)));
                this.addressOptions = addresses.map((addr) => new Object({'id': addr.id, 'name': (addr.branch ? addr.branch : 'Default'), 'content': `${addr.street}\n${addr.country.name}`}));
                if(!address) address = addresses[0];
                if(!this.invoice.customer_address_id) this.invoice.customer_address_id = address.id;
                
                this.invoice.customer_address = [];
                if(address.po_box) {
                    this.invoice.customer_address.push(address.po_box);
                } else {
                    if(address.street) this.invoice.customer_address.push(address.street);
                    if(address.street_additional) this.invoice.customer_address.push(address.street_additional);
                }
                if(address.postal_code || address.city) this.invoice.customer_address.push(address.postal_code + ' ' + address.city);                
                if(address.country.name) this.invoice.customer_address.push(address.country.name);
                this.invoice.customer_address = this.invoice.customer_address.join('\n');
                this.isVATRequired = address.country.is_eu && isCompany;
                this.customerIsNaturalPerson = !isCompany;
            },
            
            getVatPercentages() {
                if(this.loading || !this.invoice.office_id) return;
                this.loading = true;
                relationService.getVatPercentages(this.invoice.office_id, true).then(response => {
                    if(response.data){
                        this.vatAmounts = [{id: 0, name: '0%'}];
                        this.office = response.data.office;
                        if(!this.invoice.our_vat_id)
                            this.invoice.our_vat_id = this.office.relation.vat;
                        
                        const countrycode = this.office.finance_setting ? this.office.finance_setting.country_code_invoice_number : this.office.relation.country.iso;
                        const officecode = this.office.finance_setting ? this.office.finance_setting.office_code_invoice_number : (this.office.id < 10 ? ('0' + this.office.id) : this.office.id);
                        const year = moment().format('YY');
                        if(!this.invoice.is_finalized){
                            if(this.$parent.$parent.isCopying){
                                this.invoice.number = `${countrycode}${officecode}-${year}*****`;
                            }
                            else if(this.isReadyInvoice || this.isFromShipload || !this.isEditing){
                                this.invoice.number = `${countrycode}${officecode}-${year}*****`;
                                if(this.office.relation.country.default_currency_id){
                                    this.invoice.currency_id = this.noEdit ? this.invoice.currency_id : this.office.relation.country.default_currency_id;
                                    if(!this.prevInvoiceCurr){
                                        this.prevInvoiceCurr = this.office.relation.country.default_currency_id;
                                    }
                                    this.defaultCurrency = this.office.relation.country.default_currency_id;
                                    if(!this.isEditing && this.invoice.invoice_lines[0] && !this.invoice.invoice_lines[0].sales_rate){
                                        this.invoice.invoice_lines[0].sales_rate_currency_id = this.defaultCurrency;
                                    }
                                }
                                this.targetCurrency = this.currencies.find(currx => currx.id == this.invoice.currency_id);
                                
                                for(let key in this.invoice.exchange_rate){
                                    if(key == this.targetCurrency.id || this.isFromShipload) continue;
                                    if(this.legacyExchangeRates[key] !== undefined){
                                        this.invoice.exchange_rate[key].value = this.legacyExchangeRates[key].value;
                                        continue;
                                    }
                                    const currency = this.currencies.find(currx => currx.id == key);
                                    const exRate = this.targetCurrency.currency_rates.exchange_rate_eur / currency.currency_rates.exchange_rate_eur;
                                    this.invoice.exchange_rate[key].value = Number(exRate).toFixed(4);
                                }
                            }
                            else if(this.isEditing && countrycode != this.invoice.number.substring(0, 2)){
                                this.invoice.number = `${countrycode}${officecode}-${year}*****`;
                            }
                        }
                        response.data.vat_percentages.forEach(vat => {
                            this.vatAmounts.push({id: vat.percentage, name: (vat.percentage + '%')});
                        });
                    }
                    this.loading = false;
                    this.$nextTick(() => this.invoiceOnChange() );
                }).catch(error => console.log('error', error)
                ).finally(() => this.loading = false);
            },
            getCustomerInfo(){
                this.gettingCustomerInfo = true;
                this.getCustomerAddress(true);
            },
            setDefaultOpen(isOpen){
                this.defaultIsOpen = isOpen;
                if(isOpen) this.$refs.defcostPopup.reset();
            },
            findCurrency(id){
                const match =  this.currencies.find(c => c.id == id);
                return match ? match.code : '';
            },
            invoiceOnChange(setFlatFee = false, item = null){
                if(this.invoice.fac == -1 && this.invoice.fac_manual == '')
                    this.invoice.fac = null;
                if(!this.targetCurrency)
                    this.targetCurrency = this.currencies.find(currx => currx.id == this.defaultCurrency);
                if(setFlatFee && item != null && item.sales_rate_calculation_unit_id == 6)
                    item.quantity = 1;
                if(this.invoice.payment_terms_id === null)
                    this.invoice.payment_terms_id = 7;

                    console.log("Fac", this.invoice.fac);
                this.getInvoiceGrandTotal();
                this.$forceUpdate();
            },
            exRateOnChange(item, id){
                const nval = Number(item.display_value);
                item.value = id != this.companyCurrency.id ? nval : (1 / nval);
                this.invoiceOnChange();
            },
            recaulculateExRate(rate, currency = null){
                let result = 1;
                const exrate = Number(rate.exchange_rate);
                if(!this.companyCurrency || (currency === null && this.targetCurrency.id == 2 && rate.currency_id == 2))
                    return exrate.toFixed(4);
                if(this.companyCurrency.id == rate.currency_id)
                    return (1 / exrate).toFixed(4);
                if(this.companyCurrency.id == this.targetCurrency.id)
                    return (exrate).toFixed(4);
                const tempCurr = currency ? currency : this.currencies.find(curr => curr.id == rate.currency_id);
                const compExRate = this.companyCurrency.currency_rates.exchange_rate_eur / (tempCurr ? tempCurr.currency_rates.exchange_rate_eur : 1);
                result = exrate * compExRate;
                return result.toFixed(4);
            },
            currencyOnChange(e){
                this.targetCurrency = this.currencies.find(currx => currx.id == e.target.value);
                let skipRate = null;
                if(this.legacyExchangeRates[this.targetCurrency.id] !== undefined && this.invoice.exchange_rate[this.targetCurrency.id] === undefined){
                    this.invoice.exchange_rate[this.targetCurrency.id] = {...this.legacyExchangeRates[this.targetCurrency.id]};
                    this.invoice.exchange_rate[this.targetCurrency.id].value = (1/this.invoice.exchange_rate[this.targetCurrency.id].value).toFixed(4);
                    skipRate = true;
                }
                for(let key in this.invoice.exchange_rate){
                    if(key == 1 && skipRate){
                        continue;
                    }
                    const nval = Number(this.invoice.exchange_rate[key].value);
                    if((key == this.prevInvoiceCurr || key == this.targetCurrency.id) && this.invoice.exchange_rate[this.targetCurrency.id]){
                        const pnval = Number(this.invoice.exchange_rate[this.targetCurrency.id].display_value);
                        let curr = this.currencies.find(tc => tc.id == this.targetCurrency.id);
                        curr = curr ? curr.code : '';
                        this.invoice.exchange_rate[key].display_value = this.invoice.exchange_rate[this.targetCurrency.id].display_value;
                        this.invoice.exchange_rate[key].value = (key != this.companyCurrency.id ? pnval : (1/pnval)).toFixed(4);
                    }
                    else{
                        this.invoice.exchange_rate[key].display_value = (key != this.companyCurrency.id ? nval : (1/nval)).toFixed(4);
                    }
                }
                if(this.invoice.vat_type_id == 3 && this.office.relation.country && this.office.relation.country.default_currency_id != this.invoice.currency_id){
                    this.invoice.vat_type_id = null;
                }
                this.getInvoiceGrandTotal();
                this.$forceUpdate();
                this.prevInvoiceCurr = this.invoice.currency_id + 1 - 1;
            },

            checkForPOLDate(){
                if(this.invoice.voyage && this.invoice.port_of_loading && !this.invoice.port_of_loading_date)
                    return invoiceService.checkForPOLDate(this.invoice.voyage, this.invoice.port_of_loading).then(response => this.invoice.port_of_loading_date = response.data.date).catch(error =>  console.log('error', error));
                if(this.invoice.port_of_loading && !this.invoice.port_of_loading_date) this.invoice.port_of_loading_date = null;
            },
            showPDF(triggerDownload = true, shouldPreviewOnly = true){
                if(!this.canEditInvoices){
                    return this.pdfDownload();
                }
                if((triggerDownload || this.jobItem) && !this.validate()){
                    return;
                }
                let invoiceForPDF = {...this.invoice};
                invoiceForPDF.total_exl_vat = this.totals.grandsub.replace(/[A-Za-z]/g, '').replace('.', '').replace(',', '.');
                invoiceForPDF.total_inc_vat = this.totals.grand.replace(/[A-Za-z]/g, '').replace('.', '').replace(',', '.');
                invoiceForPDF.invoice_lines.forEach((line, index) => {
                    if(line.sales_rate_calculation_unit_id == 1 && line.amount !== 0){
                        line.amount = 1;
                    }
                    line.order_by = index;
                });
                let rates = [];
                invoiceForPDF.calculated_totals = this.totals;
                for(var key in invoiceForPDF.exchange_rate){
                    rates.push({
                        'currency_id': key, 
                        'exchange_rate': invoiceForPDF.exchange_rate[key].display_value ? invoiceForPDF.exchange_rate[key].display_value : invoiceForPDF.exchange_rate[key].value,
                        'invoice_id': this.isReadyInvoice ? null : invoiceForPDF.id, 
                        'id': invoiceForPDF.exchange_rate[key].id
                    });
                }
                invoiceForPDF.exchange_rates = rates;
                invoiceForPDF.is_pro_forma = false;
                if(this.isReadyInvoice){
                    invoiceForPDF.voyage = this.voyageNumber;
                    invoiceForPDF.sales_manager = invoiceForPDF.sales_manager_names;
                    invoiceForPDF.job_number = invoiceForPDF.job_numbers;
                }

                if(this.invoice.is_finalized){
                    return this.pdfDownload();
                }
                if(this.isFromShipload && shouldPreviewOnly){
                    return invoiceService.showPDF(invoiceForPDF).then(response => {
                        this.triggerDownload(response.data, `${this.invoice.number}.pdf`);
                    }).catch(error => console.log('error', error));
                }

                if(this.invoice.id){
                    return invoiceService.update(invoiceForPDF).then(responsedata => {
                        responsedata.data.invoice_lines.forEach((line, index) => this.invoice.invoice_lines[index].id = line.id );
                        responsedata.data.exchange_rates.forEach((er) => this.invoice.exchange_rate[Number(er.currency_id)].id = er.id );
                        if(!invoiceForPDF.is_finalized) this.setEmailData(responsedata.data);
                        if(this.jobItem){
                            this.$parent.$toast.success(this.$t('form.updated_x', [this.$t('navigation.invoices')]));
                        }
                        if(triggerDownload) this.pdfDownload();
                    });
                }
                else{
                    return invoiceService.store(invoiceForPDF).then(responsedata => {
                        this.invoice.id = responsedata.data.id;
                        this.invoice.number = responsedata.data.number;
                        responsedata.data.invoice_lines.forEach((line, index) => this.invoice.invoice_lines[index].id = line.id );
                        responsedata.data.exchange_rates.forEach((er) => this.invoice.exchange_rate[Number(er.currency_id)].id = er.id );
                        const finishbutton = document.getElementById(this.jobItem ? 'xfinishbutton' : 'finishbutton');
                        if(finishbutton) finishbutton.disabled = false;
                        if(triggerDownload) this.pdfDownload();
                        this.setEmailData(responsedata.data);
                        if(this.jobItem){
                            const createdInvoice = {...this.invoice};
                            createdInvoice.exchange_rates = rates;
                            if(this.$parent.job.bookings[this.$parent.selectedBooking].invoices.length > 1){
                                const latestIndex = this.$parent.job.bookings[this.$parent.selectedBooking].invoices.length - 1;
                                this.$parent.job.bookings[this.$parent.selectedBooking].invoices[latestIndex] = createdInvoice;
                                this.$parent.activeInvoice = responsedata.data.id;
                            }
                            else{
                                //this.$parent.job.bookings[this.$parent.selectedBooking].invoices.push(createdInvoice);
                                this.$parent.job.bookings[this.$parent.selectedBooking].invoices[0] = (createdInvoice);
                            }
                            this.$parent.job.invoices.push(responsedata.data);
                            this.$parent.$toast.success(this.$t('form.created_x', [this.$t('navigation.invoices')]));
                            //this.$parent.job.invoices.splice(this.$parent.activeInvoice, 1);
                            document.getElementById('xemailbutton').disabled = false;
                            this.shiploadMode = null;
                            return;
                        }
                        this.$parent.$parent.getIndex();
                    });
                }
            },
            pdfDownload(){
                return invoiceService.downloadPDF(this.invoice.id).then(response => {
                    this.triggerDownload(response.data, `${this.invoice.number}.pdf`);
                }).catch(error => console.log('error', error)
                ).finally(() => this.loading = false);
            },
            email(){
                if(!this.validate()){ return; }
                this.showPDF(false);
                this.emailActive = true;
            },
            setDefaultEmailData(){
                this.emailData = {
                    attachments: [],
                    from: 'noreply@mg.nmtshipping.com',
                    to: '',
                    reply_to: '',
                    is_cc: 1,
                    is_bcc: 0,
                    cc: '',
                    bcc: '',
                    subject: '',
                    body: ''
                };
            },
            finalizeAndSendEmail(){
                if(!(this.emailData.to && this.emailData.subject)){
                    return;
                }
                return invoiceService.finalize({...this.invoice, ...this.emailData}, this.invoice.id).then(response => {
                    this.setDefaultEmailData();
                    document.getElementById("inner-close-btn").click();
                    window.setTimeout(() => {
                        this.$toast.success(this.$t('form.email_sent'));
                        if(this.jobItem){
                            this.afterFinalization(response.data.number);
                        }
                        else{
                            document.getElementById("modal-close-button").click();
                            this.$parent.$parent.getIndex();
                        }  
                    }, 100);
                }).catch(error => console.log('error', error)
                ).finally(() => this.loading = false);
            },
            finishWithoutEmail(){
                if(!this.validate() || !confirm(this.$t('accounting.finalize_invoice'))){
                    return;
                }
                return invoiceService.finalize({...this.invoice, ...{status_only: true}}, this.invoice.id).then(response => {
                    this.$toast.success(response.data.message);
                    if(this.jobItem){
                        this.afterFinalization(response.data.number);
                    }
                    else{
                        document.getElementById("modal-close-button").click();
                        this.$parent.$parent.getIndex();
                    }
                }).catch(error => console.log('error', error)
                ).finally(() => this.loading = false);
            },
            afterFinalization(newNumber){
                this.invoice.number = newNumber;
                this.invoice.is_finalized = true;
                let jbInvoice = this.$parent.job.bookings[this.$parent.selectedBooking].invoices[this.$parent.activeInvoiceIndex];
                jbInvoice.number = newNumber;
                jbInvoice.is_finalized = true;
                this.$parent.$forceUpdate();
                const el = document.querySelector('#jobTab .job-nav-button.active');
                if(el) el.innerHTML = newNumber;
            },
            dropzoneDragOver(e){
                e.preventDefault();
                if(e.target.hasAttribute('aria-hidden')){
                    e.target.style.color = this.dropHighlightColor;
                    e.target.parentElement.style.borderColor = this.dropHighlightColor;
                }
                else{
                    e.target.style.borderColor = this.dropHighlightColor;
                    e.target.style.color = this.dropHighlightColor;
                }
            },
            dropzoneDragLeave(e){
                if(e.target.hasAttribute('aria-hidden')){
                    e.target.style.color = this.dropDefaultColor;
                    e.target.parentElement.style.borderColor = this.dropDefaultColor;
                }
                else{
                    e.target.style.borderColor = this.dropDefaultColor;
                    e.target.style.color = this.dropDefaultColor;
                }
            },
            async uploadAttachment(e, isOnClick = false){
                e.preventDefault();
                if(!isOnClick){
                    this.dropzoneDragLeave(e);
                }
                if (isOnClick || e.dataTransfer.items) {
                    let files = isOnClick ? e.target.files : e.dataTransfer.items;
                    
                    for (var i = 0; i < files.length; i++) {
                        let fileitem = files[i]
                        
                        if(!isOnClick && fileitem.kind != 'file') continue;
                        let file = isOnClick ? fileitem : fileitem.getAsFile();

                        let fileSize = Math.ceil(file.size / 1024) + ' kb';
                        let fileType = file.type.split('/')[1];
                        if(file.name.match(/\.doc?$|\.docx?$|\.xls?$|\.xlsx?$|\.pdf?$|\.eml?$|\.msg?$|\.png?$|\.gif?$|\.jpg?$|\.jpeg?$|\.bmp?$|\.tif?$|\.tiff$/)){
                            this.emailData.attachments.push({
                                'type': fileType,
                                'name': file.name.replace(/[^a-z0-9.]/gi, '_'),
                                'size': fileSize,
                                'date': moment(),
                                'user': store.getters.getUser,
                                'blob': await this.blobToBase64(file),
                                'url': window.URL.createObjectURL(file)
                            });
                        }
                    }
                }
                if (isOnClick){
                    e.target.value = "";
                }
            },
            async blobToBase64(blob) {
                return new Promise((resolve, _) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result.substr(reader.result.indexOf(',')+1));
                    reader.readAsDataURL(blob);
                });
            },
            toggleDescOnly(){
                const value = this.invoice.invoice_lines[0] ? this.invoice.invoice_lines[0].amount == 0 : 0;
                this.invoice.invoice_lines.forEach(line => line.amount = value ? 1 : 0);
            },

            onDragStart(e, index){
                e.dataTransfer.setDragImage(this.dragImage, 0, 0);
                this.dragData.dragIndex = index;
                this.dragData.isDragging = true;
                this.dragData.dragger = e.target.parentElement.parentElement.cloneNode(true);
                let els = this.dragData.dragger.querySelectorAll("input");
                els.forEach(el => {
                    if(el.type === 'checkbox' && el.checked)
                        el.setAttribute('checked', "");
                    el.setAttribute('value', el.value);
                });
                els = this.dragData.dragger.querySelectorAll("textarea");
                els.forEach(el => el.innerHTML = el.value );
                els = this.dragData.dragger.querySelectorAll("select");
                els.forEach(el => {
                    const value = el.getAttribute('data-value');
                    el.children.forEach((child) => {
                        if(child.value == value) child.setAttribute('selected', '');
                    });
                });
                this.dragData.dragger = this.dragData.dragger.innerHTML;
                this.dragData.originY = e.screenY;
                this.dragData.item = {...this.invoice.invoice_lines[index]};
            },
            onDragEnd(e){
                this.dragData.isDragging = false;
                this.dragData.dragger = null;
                if(this.dragData.dropIndex == null) return;
                
                const realElement = this.dragData.item;
                const dropIndex = this.dragData.before || this.dragData.dragIndex < this.dragData.dropIndex ? this.dragData.dropIndex : this.dragData.dropIndex + 1;
                this.invoice.invoice_lines.splice(this.dragData.dragIndex, 1);
                this.invoice.invoice_lines.splice(dropIndex, 0, realElement);
                this.$nextTick(() => {
                    for(let index in this.invoice.invoice_lines) this.invoice.invoice_lines[index].order_by = Number(index);
                    this.dragData = {dragIndex: null, dropIndex: null, isDragging: false};
                })
            },
            onDragOver(e, isEnter, index){
                e.preventDefault();
                if(!e.target.classList.contains('droppable')) return;
                if(isEnter){
                    this.dragData.dropIndex = index == this.dragData.dragIndex ? null : index;
                    window.setTimeout(() => this.dragData.entering = true, 50);
                }
                else{
                    this.dragData.entering = false;
                }
            },
            setBefore(e){
                e.preventDefault();
                this.dragData.before = this.dragData.originY > e.screenY;
            },
            saveJobInvoice(){
                const createdInvoice = {...this.invoice};
                if(this.$parent.job.bookings[this.$parent.selectedBooking].invoices.length > 1){
                    const latestIndex = this.$parent.job.bookings[this.$parent.selectedBooking].invoices.length - 1;
                    this.$parent.job.bookings[this.$parent.selectedBooking].invoices[latestIndex] = createdInvoice;
                    //this.$parent.activeInvoice = responsedata.data.id;
                }
                else{
                    //this.$parent.job.bookings[this.$parent.selectedBooking].invoices.push(createdInvoice);
                    this.$parent.job.bookings[this.$parent.selectedBooking].invoices[0] = (createdInvoice);
                    console.log(createdInvoice);
                }
            },
            
            checkData(){
                this.dataItemsLoaded++;
                if(this.dataItemsLoaded >= this.dataItemsToLoad){
                    this.defaultVatAmounts = dataStore.getters.getVatTypes;
                    if(this.shiploadMode)
                        this.setDataShipload(false);
                    else if(this.isEditing)
                        this.setData(false);
                    else
                        this.reset();
                }
            }
        },
        mounted() {
            //Transparent image
            this.dragImage = new Image();
            this.dragImage.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
        }
    }
</script>

<style scoped>
.transport-unit-table.row{
    padding-bottom: 2px;
    padding-top: 2px
}
.col-icon{ width: 50px; padding: 0 !important }
.col-tiny{ width: 25px }
.col-3p5{ width: 397px; flex: unset }
.col-sm-xs{ flex: 0 0 auto; width: 100px }
.all-totals-column div{ margin: 2px 0 }
.invisible, #currencylist label:not(:first-child){ visibility: hidden }
#invoiceoptions { overflow: hidden }
#invoiceoptions .row { --bs-gutter-x: 5rem }

.total-field, .totals-row {
    font-size: .875rem;
    padding-left: 0 !important;
    padding-top: 4px;
}

.col-combined {
    position: relative; 
    padding-right: 1.5rem;
}

.droppable > div{ pointer-events: none }
.droppable:not(.dragging-row){ opacity: 60% }
.opaque > div{ display: none }
.fa-sort{ padding-top: 4px }

.file-drop{
    border: 3px dashed #888;
    color: #888;
    text-align: center;
    padding: 1rem;
    border-radius: 0.5rem
}
.file-drop:hover{
    border-color: #0d6efd !important;
    color: #0d6efd !important;
    cursor: pointer
}

.no-resize{
    resize: none;
    height: 1.5rem;
    background-position: right calc(.375em + .1875rem) center !important
}
.dropdown-item:hover {
    cursor: pointer;
    background-color: #ddd;
}
</style>
<style>
    #invoiceoptions .auto-check-label { width: calc(100% - 73px) }
    .col-combined .w-67:not(.tms) { width: calc(min(100% - 70px, 66%)) }
    #invoiceoptions #loading_port, #invoiceoptions #discharge_port{ text-transform: capitalize }
    #invoices-popup.read-only input:not([type="checkbox"]), #invoices-popup.read-only select, #invoices-popup.read-only textarea, #invoices-popup.read-only .curr-preview{
        pointer-events: none;
        background-color: #e9ecef;
    }
    #invoices-popup.read-only input[type="checkbox"]{
        pointer-events: none;
        opacity: 60%;
    }
    #invoices-popup.read-only label, #invoices-popup.read-only .fa-sort, #invoices-popup.read-only .dp__main{
        pointer-events: none;
    }
    #invoices-popup.read-only button{
        pointer-events: none;
        visibility: hidden;
    }
    #invoices-popup.read-only #savebutton{
        display: none
    }
</style>