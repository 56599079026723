<template>
    <div class="d-flex" v-if="isEditing">
        <div class="col-9">
            <template v-for="booking, index in job.bookings" :key="index">
                <template v-for="voyage, vindex in booking.job_booking_voyage" :key="vindex">
                    <div class="row mb-2">
                        <div class="col-0p5 fw-bold">{{ (index + 1) }} </div>
                        <div class="col-0p5">
                            <FormItem :id="'check-all-'+voyage.id" type="checkbox" @onclick="(e) => addPDFUnits(voyage.id, booking.id, !e.target.checked)" />
                        </div>
                        <div class="col-11 fw-bold ps-0">{{getBookingTabName(booking, vindex)}} </div>
                    </div>
                    <template v-for="unit, uIndex in booking.job_unit" :key="uIndex">
                        <div class="row" :data-booking="booking.id" :data-vindex="voyage.id + 'b' + booking.id" :class="{'cancelled': unit.is_cancelled}">
                            <div class="col-0p5">{{ (uIndex + 1) }}</div>
                            <div class="col-0p5"> 
                                <FormItem :id="'check-booking-'+uIndex+'-'+booking.id+'-'+voyage.id" type="checkbox" @onclick="(e) => addPDFUnit(voyage, unit, !e.target.checked)" :disabled="unit.is_cancelled" />
                            </div>
                            <div style="width: 23%" class="col-auto ps-0 text-ellipsis">{{ unit.commodity.name + ', ' + (unit.condition_id == 2 ? $t('form.used') : $t('form.new')) }}</div>
                            <div style="width: 20%" class="col-auto text-ellipsis"><span v-if="unit.make">{{unit.make.name}}</span> <span v-if="unit.model">{{unit.model.name}}</span></div>
                            <div style="width: 22%" class="col-auto license-plate text-ellipsis">{{ unit.chassis_number }}</div>
                            <div class="col w-25" v-if="unit.is_cancelled">
                                {{ $t('quote_status.cancelled') }} &ensp; <span class="col-1 text-link" @click="uncancelUnit(unit)">{{$t('form.undo')}}</span>
                            </div>
                            <template v-else>
                                <div style="width: 12%" class="col-1 text-ellipsis">
                                    {{ statuses[unit.status_id] }}
                                </div>
                                <div style="width: 13%" class="col-1 text-danger text-ellipsis">
                                    <span class="unit-label bg-danger" v-show="unit['is_priority_'+voyage.id]">P</span>
                                    <span class="unit-label bg-success" v-show="unit['is_cleared_'+voyage.id]">C</span>
                                    <span class="unit-label bg-info" v-show="unit['is_on_hold_'+voyage.id]">H</span>
                                    <span class="unit-label hoverable bg-warning" v-show="unit.remarks_string != ''" @mouseenter="showTooltip" @mouseleave="hideTooltip">R</span>
                                    <pre class="remark-blurb">{{unit.remarks_string}}</pre>
                                </div>
                            </template>
                        </div>
                        <div class="row" v-for="sunit in unit.stacked_units" :key="sunit.id" :class="{'cancelled': unit.is_cancelled}">
                            <div class="col-0p5"></div>
                            <div class="col-0p5"><i class="fa fa-xs ps-1 fa-turn-down-right"></i></div>
                            <div style="width: 23%" class="col-auto ps-0 text-ellipsis">{{ sunit.commodity.name + ', ' + (sunit.condition_id == 2 ? $t('form.used') : $t('form.new')) }}</div>
                            <div style="width: 20%" class="col-auto text-ellipsis"><span v-if="sunit.make">{{sunit.make.name}}</span> <span v-if="sunit.model">{{sunit.model.name}}</span></div>
                            <div style="width: 22%" class="col-auto license-plate text-ellipsis">{{ sunit.chassis_number }}</div>
                        </div>
                    </template>
                    <br />
                </template>
            </template>
        </div>
        <div class="col-3">
            <h5>Documentation</h5>
            <FormItem type="download" :label="$t('operations.all_bls')" :service="downloadAllBLs" :options="{fullWidth: true, small: true, margin: true}" />
            <FormItem type="download" :label="$t('operations.excel_list')" :service="downloadExcelList" :options="{fullWidth: true, small: true, margin: true}" startTag="fa fa-file-excel" />
            <FormItem type="download" :label="$t('operations.booking_summary') + (unitsForPDF.length > 0 ? (' ('+unitsForPDF.length+')') : '')" :disabled="unitsForPDF.length == 0" 
                     :service="downloadBookingSummary" :options="{fullWidth: true, small: true, margin: true}" />
            <FormItem type="download" :label="$t('operations.booking_request') + (unitsForPDF.length > 0 ? (' ('+unitsForPDF.length+')') : '')" :disabled="unitsForPDF.length == 0" 
                     :service="downloadBookingRequest" :options="{fullWidth: true, small: true, margin: true}" />
            <FormItem type="download" :label="$t('operations.delivery_instructions') + (unitsForPDF.length > 0 ? (' ('+unitsForPDF.length+')') : '')" :disabled="unitsForPDF.length == 0" 
                     :service="downloadDeliveryInstructions" :options="{fullWidth: true, small: true, margin: true}" />
            <FormItem type="download" :label="$t('operations.master_bl') + (unitsForPDF.length > 0 ? (' ('+unitsForPDF.length+')') : '')" :disabled="unitsForPDF.length == 0" 
                     :service="downloadMasterBL" :options="{fullWidth: true, small: true, margin: true}" />
            <FormItem type="download" :label="$t('operations.unit_stickers') + (unitsForPDF.length > 0 ? (' ('+unitsForPDF.length+')') : '')" :disabled="unitsForPDF.length == 0" 
                     :service="downloadUnitStickers" :options="{fullWidth: true, small: true, margin: true}" />
            <!--<FormItem type="download" :label="$t('operations.excel_list') + (unitsForPDF.length > 0 ? (' ('+unitsForPDF.length+')') : '')" :disabled="unitsForPDF.length == 0" 
                     :service="downloadExcelList" :options="{fullWidth: true, small: true, margin: true}" startTag="fa fa-file-excel" />-->
            <br />
            <button type="button" :disabled="unitsForPDF.length == 0" class="w-100 btn btn-sm btn-pdf" @click="emailDocumentsModal = true">
                <i class="fa fa-envelope"></i>&ensp;E-mail documents <span v-show="unitsForPDF.length > 0">({{unitsForPDF.length}})</span>
            </button>

            <br/><br/>
            <h5>Unit actions</h5>
            <button type="button" :disabled="unitsForBulkActions.length == 0" class="w-100 mt-1 btn btn-sm btn-pdf" @click="bulkActionUnits('is_priority')">
                <i class="fa fa-square-exclamation"></i>&ensp;{{ $t('operations.status_priority') }} <span v-show="unitsForBulkActions.length > 0">({{unitsForBulkActions.length}})</span>
            </button>
            <button type="button" :disabled="unitsForBulkActions.length == 0" class="w-100 mt-1 btn btn-sm btn-pdf" @click="bulkActionUnits('is_cleared')">
                <i class="fa fa-broom-wide"></i>&ensp;{{ $t('operations.status_cleared') }} <span v-show="unitsForBulkActions.length > 0">({{unitsForBulkActions.length}})</span>
            </button>
            <button type="button" :disabled="unitsForBulkActions.length == 0" class="w-100 mt-1 btn btn-sm btn-pdf" @click="bulkActionUnits('is_on_hold')">
                <i class="fa fa-circle-stop"></i>&ensp;{{ $t('operations.status_on_hold') }} <span v-show="unitsForBulkActions.length > 0">({{unitsForBulkActions.length}})</span>
            </button>
            <br /><br/>
            <button type="button" :disabled="unitsForBulkActions.length == 0" class="w-100 mt-1 btn btn-sm btn-pdf" @click="cancelUnits()">
                <i class="fa fa-ban"></i>&ensp;{{ $t('operations.cancel_units') }} <span v-show="unitsForBulkActions.length > 0">({{unitsForBulkActions.length}})</span>
            </button>
            <button type="button" :disabled="unitsForBulkActions.length == 0" class="w-100 mt-1 btn btn-sm btn-pdf" @click="changeVoyageOpen()">
                <i class="fa fa-repeat"></i>&ensp;{{ $t('operations.change_voyage') }} <span v-show="unitsForBulkActions.length > 0">({{unitsForBulkActions.length}})</span>
            </button>
        </div>
    </div>
    <br>
    <template v-if="isEditing && job.units.length > 0">
        <p class="my-2">{{ $t('operations.pending_units') }}&ensp;<span class="badge">{{job.units.length}}</span></p>
        <button type="button" class="btn btn-primary" @click="$parent.setData(job, true)"><i class="fa fa-plus"></i>&ensp;{{ $t('operations.create_booking') }}</button>
    </template>

    <template v-if="emailDocumentsModal">

        <div class="modal d-block" tabindex="-1" style="background-color: rgba(1,1,1,0.4)"  @click="closeInnerModel">
            <div class="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
                <div class="modal-content" style="height: auto;">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel"><span>E-mail documents</span><span id="detailsSpan"></span></h5>
                        <button type="button" class="btn-close d-block" aria-label="Close" @click="closeInnerModel"></button>
                    </div>
                    <div class="modal-body" data-nofooter="false">
                        <form class="h-100" id="form-Colour" autocomplete="off">
                            Select the recipients to send the documents to:<br>
                            
                            <FormItem id="email_customer" :label="`Customer: ` + jobItem.customer.relation.addresses[0].email" v-model="email_customer" type="checkbox" />
                            <FormItem id="email_user" :label="`User: ` + getEmail" v-model="email_user" type="checkbox" />
                            <label for="email_otherlist" class="col-form-label col-form-label-sm auto-check-label">Other:</label>
                            <br>
                            <FormItem id="email_otherlist" v-model="email_otherlist" type="textarea" />
                        </form>
                    </div>
                    <div class="modal-footer"><button id="savebutton" type="button" class="btn btn-success" @click="emailDocuments">Send documents</button></div>
                </div>
            </div>
        </div>

    </template>

</template>

<script>
    import jobService from '@/services/JobService';
    import voyagePlanningService from '@/services/VoyagePlanningService';
    import store from '@/store/user'

    export default {
        props: ['jobItem', 'isEditing'],
        name: 'JobBookingForm',
        computed: {
            job: {
                get() {
                    return this.jobItem
                }
            },
            getEmail () {
              return store.getters.getEmail
            },
            getName () {
              return store.getters.getName
            }
        },
        watch: {
            jobItem: {
                deep: true,
                handler(jobItem) {
                    this.$emit('contractcommoditychange', jobItem)
                }
            }
        },
        data() {
            return {
                statuses: ['', '', '', '', ''],
                unitsForPDF: [],
                unitsForBulkActions: [],
                unitList: [],
                jUnitList: [],
                voyageList: [],
                emailDocumentsModal: false,
                email_customer: false,
                email_user: false,
                email_other: false,
                email_otherlist: ""
            }
        },
        methods: {
            reset() {
                const units = document.querySelectorAll('#bookunit input[type="checkbox"]');
                units.forEach(field => {
                    if(field.checked){
                        field.click();
                    }
                });
                this.unitsForPDF = [];
                this.unitsForBulkActions = [];
                this.unitList = [];
                this.jUnitList = [];
                this.voyageList = [];
            },
            downloadAllBLs(){
                return this.downloadPDF('all-bls', [this.job.id], this.job.number + '_bl_summaries');
            },
            downloadBookingSummary(){
                return this.downloadPDF('booking-summary', this.unitsForPDF.join('-'), this.job.number + '_booking_summary');
            },
            downloadBookingRequest(){
                return this.downloadPDF('booking-request', this.unitsForPDF.join('-'), this.job.number + '_booking_request');
            },
            downloadDeliveryInstructions(){
                return this.downloadPDF('delivery-instructions', this.unitsForPDF.join('-'), this.job.number + '_delivery_instructions');
            },
            downloadMasterBL(){
                return this.downloadPDF('master-bl', this.unitsForPDF.join('-'), this.job.number + '_master_bl');
            },
            downloadUnitStickers(){
                return this.downloadPDF('unit-stickers', this.unitsForPDF.join('-'), this.job.number + '_unit_stickers');
            },
            downloadPDF(type, value, name) {
                return jobService.downloadPDF(type, value).then(response => {
                    this.triggerDownload(response.data, name + ".pdf");
                }).catch(error => {
                    this.toastError(error)
                });
            },
            downloadExcelList(){
                return jobService.downloadPDF("excel", this.job.id).then(response => {
                    this.triggerDownload(response.data, "job_export_" + this.job.number + ".xlsx");
                }).catch(error => {
                    this.toastError(error)
                });
            },
            getBookingTabName(booking, vindex){
                try{
                    const bvoyage = booking.job_booking_voyage[vindex];
                    const voyage = booking.voyages[vindex];
                    const nameheader = this.$t('operations.booking') + ' ' + booking.number;
                    const vesselheader = voyage.vessel.name + ' ' + voyage.number;
                    const carrer = voyage.carrier;
                    return nameheader + ": " + booking.job_unit.length + ' ' + this.$tc('navigation.units', booking.job_unit.length).toLowerCase() + ', ' + vesselheader + 
                        ', (' + (carrer ? carrer.relation.name : '') + '), ' + bvoyage.port_of_loading.code + ' > ' + bvoyage.port_of_discharge.code;
                }
                catch(e){
                    const voyage = booking.voyages[0] ? booking.voyages[0] : {vessel: {}};
                    const nameheader = this.$t('operations.booking') + ' ' + booking.number;
                    const vesselheader = voyage.vessel.name + ' ' + voyage.number;
                    const carrer = voyage.carrier;
                    return nameheader + ": " + booking.job_unit.length + ' units, ' + vesselheader + ', (' + (carrer ? carrer.relation.name : '') + '), ' + 
                           this.job.port_of_loading.code + ' > ' + this.job.port_of_discharge.code;
                }
                
            },
            addPDFUnit(voyage, someUnit, remove){
                if(remove){
                    const index = this.unitsForPDF.findIndex(unit => unit.id == someUnit.id);
                    this.unitsForPDF.splice(index, 1);
                    this.unitsForBulkActions.splice(index, 1);
                    this.unitList.splice(index, 1);
                    this.jUnitList.splice(index, 1);
                    this.voyageList.splice(index, 1);
                }
                else{
                    this.unitsForPDF.push(someUnit.id);
                    this.unitsForBulkActions.push(someUnit['id_' + voyage.id]);
                    this.unitList.push(someUnit);
                    this.jUnitList.push(someUnit.job_booking_voyage_job_units.find(vju => vju.job_booking_voyage_id == voyage.id));
                    this.voyageList.push(voyage.voyage_id);
                }
            },
            addPDFUnits(voyageId, booking, remove){
                const units = document.querySelectorAll('#bookunit div[data-vindex="'+(voyageId + 'b' + booking)+'"] input');
                units.forEach(field => {
                    if(!field.checked ^ remove){
                        field.click();
                    }
                });
            },
            bulkActionUnits(field){
                let newValue = false;
                this.jUnitList.forEach(unit => {
                    if(unit[field] === false){
                        newValue = true;
                    }
                });
                this.jUnitList.forEach(unit => {
                    unit[field] = newValue;
                    this.unitList.find(punit => punit.id == unit.job_unit_id)[field + '_' + unit.job_booking_voyage_id] = newValue;
                });

                voyagePlanningService.update({'field': field, 'new_value': newValue, 'voyage_id': null, 'units': this.unitsForBulkActions}).then(response => {
                    console.log('OK');
                    this.$toast.success(this.$t('form.updated_x', [this.$tc('navigation.units', 2)]));
                }).catch(error => {
                    this.toastError(error)
                });
            },
            cancelUnits(){
                if(confirm(this.$t('operations.cancel_units_message'))){
                    voyagePlanningService.update({'field': 'is_cancelled', 'new_value': true, 'voyage_id': null, 'units': this.unitsForPDF}).then(response => {
                        console.log('OK');
                        this.unitList.forEach(unit => {
                            unit.is_cancelled = true;
                        });
                        this.$nextTick(() => this.$refs.proforma.setQuoteInvoiceLines());
                    }).catch(error => {
                        this.toastError(error)
                    });
                }
            },
            uncancelUnit(unit){
                if(confirm(this.$t('operations.cancel_units_message_alt'))){
                    voyagePlanningService.update({'field': 'is_cancelled', 'new_value': false, 'voyage_id': null, 'units': [unit.id]}).then(response => {
                        console.log('OK');
                        unit.is_cancelled = false;
                        this.$nextTick(() => this.$refs.proforma.setQuoteInvoiceLines());

                    }).catch(error => {
                        this.toastError(error)
                    });
                }
            },
            changeVoyageOpen(){
                this.$parent.changeVoyageIsOpen = true;
                this.$parent.$refs.changeVoyagePopup.resetWithJob(this.$parent.job, this.$parent.$refs.bookingCreate.filteredVoyages, this.voyageList);
            },
            changeVoyage(value){
                let newValue = [{'voyage': value, 'job': this.$parent.job.id, 'units': [], 'units_voyage': [], 'booking_ids': [], 'split_booking': false}];
                this.unitList.forEach(unit => {
                    //unit.selected = false;
                    newValue[0].units.push(unit.id);
                });
                this.jUnitList.forEach(unit => {
                    newValue[0].units_voyage.push(unit.id);
                });
                const allUnits = document.querySelectorAll('#bookunit input[type="checkbox"]');
                let voyageId = null;
                let checkedUnits = [];
                let uncheckedUnits = [];
                allUnits.forEach(el => {
                    const bookingid = Number(el.id.split('-')[3]);
                    if(el.checked){
                        if(bookingid && !newValue[0].booking_ids.includes(bookingid)){
                            newValue[0].booking_ids.push(bookingid);
                            checkedUnits.push(bookingid);
                        }
                    }
                    else if(bookingid){
                        uncheckedUnits.push(bookingid);
                    }
                });
                checkedUnits.forEach(unit => {
                    if(uncheckedUnits.includes(unit)){
                        newValue[0].split_booking = true;
                    }
                });
                if(!newValue[0].split_booking){
                    newValue[0].split_booking = newValue[0].booking_ids.length != 1;
                }
                if(!newValue[0].split_booking){
                    console.log(this.job.bookings, newValue);
                    voyageId = this.job.bookings.find(b => b.id == newValue[0].booking_ids[0]).voyages[0].id;
                }

                voyagePlanningService.update({'field': 'voyage', 'new_value': newValue, 'voyage_id': voyageId, 'units': []}).then(response => {
                    console.log('OK');
                    jobService.show(this.$parent.job.id).then(response => {
                        this.$parent.setData(response.data);
                    }).catch(error => {
                        this.toastError(error)
                    })
                }).catch(error => {
                    console.log('error', error);
                });
            },
            closeInnerModel(e){
                if(e.target.classList.contains('d-block')){
                    this.emailDocumentsModal = false;
                }
            },
            emailDocuments(e) {
                
                jobService.emailDocuments(this.jobItem.id, this.unitsForPDF, this.email_customer, this.email_user, this.email_otherlist).then(response => {
                    this.emailDocumentsModal = false;
                    if(response.data.emails_failed == 0){
                        this.$toast.success(this.$tc('operations.' + response.data.message, response.data.emails_sent));
                    }
                    else{
                        this.$toast.error(this.$tc('operations.' + response.data.message, response.data.emails_failed));
                    }
                }).catch(error => {
                    this.toastError(error);
                });
            },
            showTooltip(e){
                var tooltip = e.target.nextElementSibling;
                tooltip.classList.add('show');
                var x = 0;
                var ele = e.target;
                while(ele.offsetParent !== null && ele.id != 'main-table'){
                    x += ele.offsetLeft;
                    ele = ele.offsetParent;
                }
                tooltip.style.left = (x + (10 - (tooltip.scrollWidth / 2))) + 'px';
                tooltip.style.transform = 'translateY(-' + (tooltip.scrollHeight + ele.scrollTop + 20) + 'px)';
            },
            hideTooltip(e){
                e.target.nextElementSibling.classList.remove('show');
            },
        },
        mounted(){
            this.statuses = ['', this.$t('overview.vp_waiting'), this.$t('overview.vp_firm'), this.$t('overview.vp_permis'), this.$t('operations.date_loaded')]
        }
    }
</script>

<style scoped> 
    .btn-pdf:not([disabled]){
        background-color: #0d6efd;
        border-color: #0d6efd;
        color: #fff
    }
    .btn-pdf[disabled]{
        background-color: #999;
        border-color: #999;
        color: #fff
    }
    .disabled{
        background-color: #e9ecef
    }
    .cancelled .col-auto{
        text-decoration: line-through
    }
    .text-link{
        color: #00f;
        text-decoration: underline;
    }
    .text-link:hover{
        color: #66f;
        cursor: pointer;
    }
    .unit-label{
        color: white;
        border-radius: 4px;
        display: inline-block;
        width: 16px;
        text-align: center;
        font-size: 0.75rem;
        margin-right: 2px;
    }
    .hoverable{
        cursor: pointer
    }
    .remark-blurb{
        display: none;
        position: fixed;
        text-align: center;
        z-index: 1111;
        background-color: #333;
        color: white;
        border-radius: 4px;
        padding: 4px;
        max-width: 250px;
        font-size: 0.8rem;
        font-family: unset
    }
    .remark-blurb.show{
        display: block !important;
    }
</style>