<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="$t('overview.my_x', [this.formatModuleName(this.$route.name, this.$i18n)])"
        :sort="'number'"
        :sortOrder="-1"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :modalSize="'modal-xl'"
        :modalWidth="'1400px'"
        :modalHeight="'90%'"
        :showCopy="true"
        downloadFileType="xlsx"
        :selectFields=selectFields
        :filterOptions="filterOptions"
        ref="overview"
    >
        <template #columns>
            <Column field="number" :header="$t('overview.number')" style="width: 120px" :sortOrder="-1"></Column>
            <Column field="region_type_id" :header="$t('overview.type')" style="width: 120px">
                <template #body="slotProps">
                    {{$t('service.'+slotProps.data.region_type_id)}}
                </template>
            </Column>
            <Column :header="$t('sales.contract_via')" style="width: 180px">
                <template #body="slotProps">
                    {{ calcOffice(slotProps.data) }}
                </template>
            </Column>
            <Column :header="$tc('navigation.carriers', 2)">
                <template #body="slotProps">
                    {{getCarrierNames(slotProps.data.carriers)}}
                </template>
            </Column>
            <Column :header="$t('navigation.regions')">
                <template #body="slotProps">
                    {{calcRegion(slotProps.data)}}
                </template>
            </Column>
            <Column field="start_date" :header="$t('sales.validity')" style="width: 180px" :sortOrder="-1">
                <template #body="slotProps">
                    {{ calcValidity(slotProps.data) }}
                </template>
            </Column>
        </template>

        <template #form>
            <TariffForm ref="ItemForm" :itemData="item" />
        </template>

        <template #modal-footer>
            <button id="nextbutton" type="button" class="btn btn-success" @click.prevent="next">&gt;&ensp;{{$t('form.continue_to_step')}}<span id="nextStep">2</span></button>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import TariffForm from '@/components/tariff/TariffForm.vue';
import tariffService from '@/services/TariffService';
import carrierService from '@/services/CarrierService';
import moment from 'moment';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        TariffForm
    },
    data() {
        return {
            item: {},
            itemService: tariffService,
            itemFormRef: null,
            filterOptions: [
                {
                    field: 'region_type_id',
                    radio: [{id: 1, name: this.$t('service.1')}, {id: 2, name: this.$t('service.2')}],
                    name: this.$t('overview.type')
                },
                {
                    field: 'office_creator_id',
                    radio: [{id: store.getters.getCompanyId, name: this.$t('overview.direct')}, {id: ('NOT!'+store.getters.getCompanyId), name: this.$t('overview.other_nmt')}],
                    name: this.$t('sales.contract_via')
                },
                {
                    field: 'carriers.relation_id',
                    service: carrierService,
                    name: this.$t('navigation.carriers'),
                    idField: 'relation_id',
                    multiple: true
                }
            ],
            selectFields: [
                "id",
                "number",
                "region_type_id",
                "start_date",
                "end_date",
                "office_creator_id",
                {
                    "office": [
                        "id",
                        "relation_id",
                        "prefix",
                        {
                            "relation": [
                                "relations.id",
                                "country_id",
                                {
                                    "country": [
                                        "id",
                                        "iso"
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "carriers": [
                        "carriers.id",
                        "relation_id",
                        {
                            "relation": [
                                "relations.id",
                                "name"
                            ]
                        }
                    ]
                },
                {
                    "tariffItems": [
                        "id",
                        "tariff_id",
                        {
                            "loadingPort": [
                                "id",
                                "short_sea_region_id",
                                "deep_sea_region_id",
                                {
                                    "shortSeaRegion": [
                                        "id",
                                        "name"
                                    ]
                                },
                                {
                                    "deepSeaRegion": [
                                        "id",
                                        "name"
                                    ]
                                }
                            ]
                        },
                        {
                            "dischargePort": [
                                "id",
                                "short_sea_region_id",
                                "deep_sea_region_id",
                                {
                                    "shortSeaRegion": [
                                        "id",
                                        "name"
                                    ]
                                },
                                {
                                    "deepSeaRegion": [
                                        "id",
                                        "name"
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    },
    methods: {
        next() {
            let tabs = document.querySelectorAll("#formModal ul.nav-tabs .nav-link");
            let next = false;
            for(let tab of tabs) {
                if(next) {
                    tab.click();
                    break;
                }
                if(tab.className == "nav-link active") next = true;
                
            }
        },
        getCarrierNames(data){
            let result = "";
            data.forEach(el => { result += (el.relation.name + ', ') });
            result = result.slice(0, -2);
            if(result.length > 26){
                result = result.slice(0, 25) + '...';
            }
            return result;
        },
        calcRegion(data){
            let key = data.region_type_id == 1 ? 'short_sea_region' : 'deep_sea_region';
            let allRegionNames = [];
            let allRegionIds = [];
            function lookForPortRegions(port){
                try{
                    const id = port[key].id;
                    const name = port[key].name;
                    if(!allRegionIds.includes(id) ) {
                        allRegionNames.push(name);
                        allRegionIds.push(id);
                    }
                }
                catch(e){
                    console.log(e);
                }
            }
            data.tariff_items.forEach(item => {
                item.loading_port.forEach(port => lookForPortRegions(port));
                item.discharge_port.forEach(port => lookForPortRegions(port));
            });
            return allRegionNames.join(' - ');
        },
        calcOffice(data){
            return data.office_creator_id == this.$refs.overview.get('auth_office') ? this.$t('overview.direct') : (data.office.prefix + data.office.relation.country.iso)
        },
        calcValidity(data){
            const sdate = moment(data.start_date).locale(this.$i18n.locale);
            const edate = moment(data.end_date).locale(this.$i18n.locale);
            const sday = sdate.date();
            const smonth = sdate.month() + 1;
            const eday = edate.date();
            const emonth = edate.month() + 1;
            const year = edate.year() - 2000;
            if(sday == 1 && smonth == 1 && eday == 31 && emonth == 3){
                return "Q1 '" + year
            }
            if(sday == 1 && smonth == 4 && eday == 30 && emonth == 6){
                return "Q2 '" + year
            }
            if(sday == 1 && smonth == 7 && eday == 30 && emonth == 9){
                return "Q3 '" + year
            }
            if(sday == 1 && smonth == 10 && eday == 31 && emonth == 12){
                return "Q4 '" + year
            }
            const smonthtext = sdate.format("MMM").substr(0, 3);
            const emonthtext = edate.format("MMM").substr(0, 3);
            return smonthtext + ' ' + sday + ' - ' + emonthtext + ' ' + eday + " '" + year;
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>